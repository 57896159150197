import { push } from "connected-react-router";
import { DateTime } from "luxon";
import React, { Component } from "react";
import { connect } from "react-redux";
import Strings from "utils/strings";
import "./styles.scss";

class WorksheetCard extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	getStatus(status: string) {
		if (status === "pending") return Strings.worksheets.pending;
		if (status === "started") return Strings.worksheets.started;
		if (status === "finished") return Strings.worksheets.finished;
		return status;
	}

	render() {
		const { worksheet, dispatch } = this.props;
		const { _id, date, status, origin, destination, customer, exitHour, number, vehicle, driver, helper, helper2, helper3 } = worksheet || {};

		let helpers = null;
		if (helper?.name) {
			helpers = helper.name
			if (helper2?.name) {
				helpers = helpers + ' / ' + helper2.name
				if (helper3?.name) {
					helpers = helpers + ' / ' + helper3.name
				}
			}
		}

		return (
			<button
				onClick={() => dispatch(push(`/worksheets/${_id}`))}
				className={`WorksheetCardContainer${status !== "pending" ? " --worksheet-started" : ""}`}
			>
				<p>
					<strong>{number ? number + ' - ' : ''}{DateTime.fromISO(date).toLocal().toFormat("dd/MM/yyyy")}</strong>
				</p>
				<p>
					<strong>{Strings.vehicles.vehicle}</strong>: {vehicle?.licensePlate || "-"}
				</p>
				<p>
					<strong>{Strings.staff.driver}</strong>: {driver?.name || "-"}
				</p>
				<p>
					<strong>{Strings.staff.helpers}</strong>: {helpers || "-"} 
				</p>
				<p>
					<strong>{Strings.worksheets.status}</strong>: {this.getStatus(status)}
				</p>
				<p>
					<strong>{Strings.customers.customer}</strong>: {customer?.name || "-"}
				</p>
				<p>
					<strong>{origin ? Strings.worksheets.route : Strings.worksheets.destination}</strong>: {origin ? origin + " - " + destination : destination || "-"}
				</p>
				{(Boolean(exitHour) && (
					<p>
						<strong>{Strings.worksheets.exitHour}</strong>:{" "}
						{DateTime.fromISO(exitHour).toLocal().toFormat("HH:mm") || "-"}
					</p>
				)) ||
					null}
			</button>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(WorksheetCard);
