// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DashboardCard{border-radius:4px;padding:0}.DashboardCard.__Selected{border:1px solid #373736;box-shadow:0 1px 2px -2px rgba(55,55,54,.25),0 3px 6px 0 rgba(55,55,54,.2),0 5px 12px 4px rgba(55,55,54,.15)}.DashboardCard.__Selected .ant-card-head{background-color:#373736;color:#fff}.DashboardCard.__Selected .ant-card-head .ant-card-extra{color:#fff}.DashboardMainChart{display:flex;flex-direction:column;width:100%;max-width:100%}.DashboardMainChartTitle{display:flex;align-items:center;margin:10px 0}.DashboardMainChartTitle span{color:#333;font-size:20px}.DashboardMainChartTitle em{font-size:28px;margin-right:10px}.DashboardCardIcon{font-size:20px}.Dashboard_Data_Charts{height:500px;position:relative;margin-top:20px}.Dashboard_Data_Charts.__marginTop{margin-top:20px}.Dashboard_Generic_Chart{height:500px;width:100%;position:absolute;left:0;top:0;opacity:0;transition:.2s}.Dashboard_Generic_Chart.__active{opacity:1;z-index:1}.CardValue{font-size:1.2em;margin-bottom:0}.DashboardWorksheet{border-radius:4px}.DashboardWorksheetTitle{display:flex;align-items:center;margin-bottom:10px}.DashboardWorksheetTitle p{color:#333;font-size:20px;margin:0}.DashboardWorksheetTitle em{font-size:28px;margin-right:10px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
