import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Col, DatePicker, Drawer, Input, notification, Row, Select, TimePicker, Switch, Button } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import { ContentWrapper, Icon, Table } from "components";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, setUser, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import { push, replace } from "connected-react-router";
import { DateTime } from "luxon";
import moment from "moment";
import Strings from "utils/strings";
import "./styles.scss";
import { translate } from "utils/utils";

class WorksheetDetail extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isNew: props.match?.params?.id === "new",
			loading: true,
			customers: [],
			costTypes: [],
			vehicles: [],
			drivers: [],
			helpers: [],
			extraWorkValues: [],
			hasUnsavedFields: false,
			date: moment().startOf("day"),
			returnHour: moment(),
			exitHour: moment(),
			description: "",
			notes: "",
			driver: props.user?.role === "driver" ? props.user?._id : null,
			helper: props.user?.role === "helper" ? props.user?._id : null,
			status: null,
			showDrawer: false,
			showDrawerRefuel: false,
			tempExpense: null,
			tempExtraWork: null,
			tempRefuel: null
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.worksheets.worksheet));
		this.setBreadcrumb();

		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	setBreadcrumb() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.worksheets.worksheets,
							route: "/worksheets",
							icon: "duplicate"
						},
						{
							text: this.state.isNew
								? Strings.worksheets.new
								: this.state.loading
								? Strings.generic.loading
								: `${this.state.number + " - " || ""}${
										this.state.date && moment.utc(this.state.date).format("DD/MM/YYYY")
								  }`
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitWorksheet(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match, user } = this.props;

		dispatch(setLoader(true));

		try {
			const promises = [
				API.get({
					url: Endpoints.uriWorksheets("general-info")
				})
			];

			if (!isNew) {
				promises.push(
					API.get({
						url: Endpoints.uriWorksheets(match?.params?.id)
					})
				);
			}

			const [responseGeneral, responseWorksheet] = await Promise.all(promises);

			let customers = [],
				costTypes = [],
				vehicles = [],
				drivers = [],
				helpers = [],
				worksheet = {} as any;

			if (responseGeneral?.ok) {
				const {
					customers: apiCustomers = [],
					costTypes: apiCostTypes = [],
					vehicles: apiVehicles = [],
					drivers: apiDrivers = [],
					helpers: apiHelpers = [],
				} = responseGeneral.data.results || {};
				customers = apiCustomers;
				costTypes = apiCostTypes;
				vehicles = apiVehicles;
				drivers = apiDrivers;
				helpers = apiHelpers;
			}

			if (responseWorksheet?.ok) {
				const { worksheet: apiWorksheet = {} } = responseWorksheet.data.results || {};
				worksheet = Array.isArray(apiWorksheet) ? apiWorksheet[0] : apiWorksheet;
			}

			let driver = worksheet?.driver;
			if (user?.role === "driver" && isNew) driver = user._id;

			this.setState({
				...worksheet,
				loading: false,
				driver: driver,
				helper: worksheet?.helper,
				customers,
				costTypes,
				vehicles,
				worksheet,
				drivers,
				helpers,
				date: moment.utc(worksheet.date).local(),
				exitHour: worksheet.exitHour ? moment.utc(worksheet.exitHour).local() : moment(),
				returnHour: worksheet.returnHour ? moment.utc(worksheet.returnHour).local() : moment()
			});
		} catch (err) {
			this.setState({ loading: false });
			console.log("API Error", err);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitWorksheet() {
		const {
			isNew,
			_id,
			customer,
			date,
			exitHour,
			driver,
			helper,
			helper2,
			helper3,
			vehicle,
			exitKm,
			status,
			costs,
			refuels,
			returnKm,
			returnHour,
			customerNumber,
			vehicleNumber,
			origin,
			destination,
			description,
			notes,
			withoutVehicle,
			vehiclesNumber,
			internalWork,
			workValue,
			workValueExtra,
			file,
			extraWorkValues
		} = this.state;
		const { dispatch, user } = this.props;

		if (!this.validWorksheet()) {
			return;
		}

		dispatch(setLoader(true));

		try {
			const request = isNew ? API.post : API.put;

			const body = new FormData();

			body.append("customerNumber", customerNumber || '')
			body.append("vehicleNumber", vehicleNumber || '')
			body.append("withoutVehicle", withoutVehicle || false)
			body.append("vehiclesNumber", vehiclesNumber || 0)
			body.append("internalWork", internalWork || false)
			body.append("workValue", workValue || 0)
			body.append("workValueExtra", workValueExtra || 0)
			body.append("customer", customer || null)
			body.append("vehicle", vehicle || null)
			body.append("driver", driver || null)
			body.append("helper", helper || null)
			body.append("helper2", helper2 || null)
			body.append("helper3", helper3 || null)
			body.append("origin", origin || null)
			body.append("destination", destination || null)
			body.append("description", description || null)
			body.append("notes", notes || null)
			body.append("status", isNew ? "validation" : status)
			body.append("date", moment(date).utc().startOf("day").toISOString())
			body.append("extraWorkValues", JSON.stringify(extraWorkValues))

			if (file?.file) {
				body.append("file", file.file);
			} else {
				body.append("file", file || null);
			}

			let correctExitHour: any;

			if (status === "started" || status === "finished") {
				const hour = moment(exitHour).format("HH:mm");
				const day = moment(date).format("YYYY-MM-DD")
				correctExitHour = DateTime.fromFormat(`${day} ${hour}`, 'yyyy-MM-dd hh:mm')

				body.append("exitHour", correctExitHour.toUTC().toISO())
				body.append("exitKm", exitKm)
				
				body.append("costs", JSON.stringify(costs))
				body.append("refuels", JSON.stringify(refuels))
			}

			if (status === "finished") {
				const hour = moment(returnHour).format("HH:mm");
				const day = moment(date).format("YYYY-MM-DD")
				const correctReturnHour = DateTime.fromFormat(`${day} ${hour}`, 'yyyy-MM-dd hh:mm')

				if (correctReturnHour < correctExitHour) {
					notification.warn({
						message: Strings.worksheets.worksheet,
						description: Strings.worksheets.invalidHour,
						placement: "bottomRight",
						duration: 5
					});

					dispatch(setLoader(false));

					return;
				}

				body.append("returnKm", returnKm)
				body.append("returnHour", correctReturnHour.toUTC().toISO())
				body.append("km", String(returnKm - exitKm))
			}

			const response = await request({
				url: Endpoints.uriWorksheets(isNew ? "" : _id),
				data: body
			});

			if (response.ok) {
				const { worksheet } = response.data.results || {};

				if (isNew) {
					dispatch(replace(`/worksheets/${worksheet._id}`));
					dispatch(setUser({ ...user, pendingWorksheet: worksheet }));
					this.setBreadcrumb();
				}

				if (worksheet.status === "finished") {
					const responseStaff = await API.get({ url: Endpoints.uriStaff("me") });
					if (responseStaff.ok) {
						const { staff } = responseStaff.data.results || {};
						dispatch(setUser({ ...user, pendingWorksheet: staff.pendingWorksheet }));
					}
				}

				this.setState((prevState: any) => ({
					...worksheet,
					isNew: false,
					worksheet,
					hasUnsavedFields: false,
					date: worksheet?.date ? moment.utc(worksheet.date).local() : prevState.date,
					exitHour: worksheet?.exitHour ? moment.utc(worksheet.exitHour).local() : prevState.exitHour,
					returnHour: worksheet?.returnHour ? moment.utc(worksheet.returnHour).local() : prevState.returnHour
				}));

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});

				if (user?.role === "driver" || user?.role === "helper") {
					dispatch(push("/dashboard"));
				}
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validWorksheet() {
		const { withoutVehicle, internalWork, isNew, worksheet, date, driver, vehicle, status, exitKm, exitHour, returnKm, returnHour, vehicles, customer } = this.state;
		
		if ((status === "validation" || isNew) && !internalWork && !customer) {
			notification.warn({
				message: Strings.worksheets.worksheet,
				description: Strings.worksheets.fillFields,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if ((status === "pending") && (!date || (!withoutVehicle && !driver && !vehicle) || (internalWork && !customer))) {
			notification.warn({
				message: Strings.worksheets.worksheet,
				description: Strings.worksheets.fillFields,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (status === "started" && (!date || (!withoutVehicle && !driver && !vehicle && exitKm == null) || !exitHour)) {
			notification.warn({
				message: Strings.worksheets.worksheet,
				description: Strings.worksheets.fillFields,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (
			status === "finished" &&
			(
				!date ||
				(!withoutVehicle && !driver && !vehicle && exitKm == null && returnKm == null) ||
				!exitHour ||
				!returnHour)
		) {
			notification.warn({
				message: Strings.worksheets.worksheet,
				description: Strings.worksheets.fillFields,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (status === "started" || status === "finished") {
			const selectedVehicle = vehicles.find((v: any) => v._id === vehicle);

			if (worksheet?.status !== 'finished' && exitKm < selectedVehicle?.lastUsageKm) {
				notification.warn({
					message: Strings.worksheets.worksheet,
					description: Strings.worksheets.invalidKm,
					placement: "bottomRight",
					duration: 5
				});

				return false;
			}
		}

		if (returnKm && Number(returnKm) < Number(exitKm)) {
			notification.warn({
				message: Strings.worksheets.worksheet,
				description: Strings.worksheets.impossibleMileage,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	getType(type: string) {
		const { costTypes = [] } = this.state;

		const tempType = costTypes.find((c: any) => c._id === type);
		return translate(tempType?.description) ?? "";
	}
	
	getStaff(staff: string) {
		const { helpers = [] } = this.state;

		const tempStaff = helpers.find((c: any) => c._id === staff);
		return translate(tempStaff?.name) ?? "";
	}

	renderReturn() {
		const { status, withoutVehicle, returnHour, returnKm, exitKm, notes } = this.state;

		if (status !== "finished") return null;

		return (
			<ContentWrapper extraStyle={{ padding: 20 }}>
				<div className="WorksheetTitle">
					<Icon name="truck" />
					<p>{Strings.worksheets.arrivalRegistration}</p>
				</div>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12} lg={8}>
						<label htmlFor="return_hour" className="InputLabel --label-required">
							{Strings.worksheets.returnHour}
						</label>
						<TimePicker
							id="return_hour"
							allowClear={false}
							format="HH:mm"
							style={{ width: "100%", height: 40 }}
							value={returnHour || null}
							onChange={(value: any) => {
								this.setState({ returnHour: value, hasUnsavedFields: true });
							}}
						/>
					</Col>
					{!withoutVehicle && <Col xs={24} md={12} lg={8}>
						<label htmlFor="vehicle_km_end" className="InputLabel --label-required">
							{Strings.vehicles.kms}
						</label>
						<Input
							id="vehicle_km_end"
							className="tagsSelector"
							type="number"
							min={0}
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.vehicles.kms}
							value={returnKm}
							onChange={(elem: any) =>
								this.setState({ returnKm: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>}
					{(returnKm != null && exitKm != null && !Number.isNaN(returnKm) && !Number.isNaN(exitKm) && (
						<Col xs={24} md={12} lg={8}>
							<label htmlFor="vehicle_km_total" className="InputLabel">
								{Strings.worksheets.totalKm}
							</label>
							<Input
								id="vehicle_km_total"
								className="tagsSelector"
								type="number"
								readOnly
								style={{ width: "100%", height: 40 }}
								placeholder={Strings.worksheets.totalKm}
								value={returnKm - exitKm}
							/>
						</Col>
					)) ||
						null}
					<Col xs={24} md={24} lg={24}>
						<label htmlFor="notes" className="InputLabel">
							{Strings.fields.notes}
						</label>
						<Input.TextArea
							id="notes"
							className="tagsSelector"
							placeholder={Strings.fields.notes}
							value={notes}
							rows={4}
							onChange={(elem: any) =>
								this.setState({ notes: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}

	renderInfo() {
		const {
			withoutVehicle,
			vehiclesNumber,
			internalWork,
			workValue,
			workValueExtra,
			file
		} = this.state;
		const { user } = this.props;


		const admins = user.role === 'admin' || user.role === 'sysadmin';

		return (
			<ContentWrapper extraStyle={{ padding: 20 }}>
				<div className="WorksheetTitle" style={{ paddingBottom: 30 }}>
					<Icon name="duplicate" />
					<p>{Strings.worksheets.generalInfo}</p>
				</div>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12} lg={6}>
                        <div
                            className={`General_ColorFul_Switch ${
                                withoutVehicle ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.withoutVehicle}</span>
                            <Switch
                                className={`Switch ${
                                    withoutVehicle ? "__active" : ""
                                }`}
                                checked={(withoutVehicle) || false}
								disabled={!admins}
                                size="small"
                                onChange={(value: any) => {
									this.setState({ withoutVehicle: !withoutVehicle, hasUnsavedFields: true });
                                }}
                            />
                        </div>
                    </Col>
					<Col xs={24} md={12} lg={3} style={{ marginTop: -37 }}>
						<label className='SingleLabel'>{Strings.worksheets.vehiclesNumber}</label>
						<Input
							id="work_value"
							type="number"
							value={vehiclesNumber != null ? vehiclesNumber : null}
							placeholder={Strings.worksheets.vehiclesNumber}
							disabled={!admins}
							onChange={(e: any) => {
								const vehiclesNumber = e.target.value;
								this.setState({ vehiclesNumber, hasUnsavedFields: true });
							}}
						/>
					</Col>
					<Col xs={24} md={12} lg={6}>
                        <div
                            className={`General_ColorFul_Switch ${
                                internalWork ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.internalWork}</span>
                            <Switch
                                className={`Switch ${
                                    internalWork ? "__active" : ""
                                }`}
                                checked={(internalWork) || false}
								disabled={!admins}
                                size="small"
                                onChange={(value: any) => {
									this.setState({ internalWork: !internalWork, hasUnsavedFields: true });
                                }}
                            />
                        </div>
                    </Col>
					{admins && <Col xs={24} md={12} lg={3} style={{ marginTop: -37 }}>
						<label className='SingleLabel'>{Strings.worksheets.workValue}</label>
						<Input
							id="work_value"
							type="number"
							value={workValue != null ? workValue : null}
							placeholder={Strings.worksheets.workValue}
							addonAfter="€"
							onChange={(e: any) => {
								const workValue = e.target.value;
								this.setState({ workValue, hasUnsavedFields: true });
							}}
						/>
					</Col>}
					{admins && <Col xs={24} md={12} lg={3} style={{ marginTop: -37 }}>
						<label className='SingleLabel'>{Strings.worksheets.workValueExtra}</label>
						<Input
							id="work_value_extra"
							type="number"
							value={workValueExtra != null ? workValueExtra : null}
							placeholder={Strings.worksheets.workValueExtra}
							addonAfter="€"
							onChange={(e: any) => {
								const workValueExtra = e.target.value;
								this.setState({ workValueExtra, hasUnsavedFields: true });
							}}
						/>
					</Col>}
					{file && !admins && <Col xs={24} md={12} lg={6}>
						<Button
							id="file"
							type="primary"
							value={file}
							onClick={() => {window.open(file)}}
							style={{ backgroundColor: "#22a8dd", borderColor: "#22a8dd", width: "100%" }}
						>{Strings.fields.openFile}</Button>
					</Col>}
				</Row>
			</ContentWrapper>
		);
	}

	onDropFile = async (files: any) => {
		try {
			this.setState({ file: files[files.length - 1], hasUnsavedFields: true })
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	renderDeparture() {
		const {
			customerNumber,
			vehicleNumber,
			withoutVehicle,
			customer,
			customers,
			date,
			exitHour,
			driver,
			helper,
			helper2,
			helper3,
			vehicle,
			vehicles,
			exitKm,
			status,
			drivers,
			helpers,
			origin,
			destination,
			description,
			file,
			isNew
		} = this.state;
		const { user } = this.props;

		const admins = user.role === 'admin' || user.role === 'sysadmin';
		const isHelper = user._id === helper || user._id === helper2 || user._id === helper3;
		//const isDriver = user._id === driver;

		return (
			<ContentWrapper extraStyle={{ padding: 20 }}>
				<div className="WorksheetTitle">
					<Icon name="truck" />
					<p>{Strings.worksheets.exitRegistration}</p>
				</div>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="date" className="InputLabel --label-required">
							{Strings.fields.date}
						</label>
						<DatePicker
							id="date"
							monthCellRender={(currentDate: any) => {
								return currentDate.format("MMM");
							}}
							allowClear={false}
							disabled={!isNew && !admins}
							format="DD-MM-YYYY"
							style={{ width: "100%", height: 40 }}
							value={date || null}
							onChange={(value: any) => {
								this.setState({ date: value, hasUnsavedFields: true });
							}}
						/>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label
							htmlFor="customer"
							className={`InputLabel`}
						>
							{Strings.customers.customer}
						</label>
						<Select
							id="customer"
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.customers.customer}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							disabled={!admins}
							allowClear
							value={customer || null}
							onChange={(elem: any) => {
								this.setState({
									customer: elem,
									hasUnsavedFields: true
								});
							}}
						>
							{customers.map((customer: any, index: number) => (
								<Select.Option key={`customer_${index}`} value={customer._id}>
									{customer.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="destination" className={`InputLabel`}>
							{Strings.worksheets.origin}
						</label>
						<Input
							id="origin"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.origin}
							disabled={isHelper}
							value={origin}
							onChange={(elem: any) =>
								this.setState({ origin: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="destination" className={`InputLabel`}>
							{Strings.worksheets.destination}
						</label>
						<Input
							id="destination"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.destination}
							disabled={isHelper}
							value={destination}
							onChange={(elem: any) =>
								this.setState({ destination: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="driver" className={`InputLabel ${status !== 'validation' ? "--label-required" : ""}`}>
							{Strings.staff.driver}
						</label>
						<Select
								id="driver"
								className="tagsSelector"
								style={{ width: "100%" }}
								placeholder={Strings.staff.driver}
								showSearch
								allowClear
								filterOption={(input: any, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								value={driver || null}
								disabled={!admins || withoutVehicle}
								onChange={(value: any) => {
									if (user?.role !== "driver") {
										this.setState({ driver: value, hasUnsavedFields: true });
									}
								}}
							>
								{drivers?.map((s: any) => (
									<Select.Option key={s?._id} value={s._id}>
										{s?.name}
									</Select.Option>
								))}
						</Select>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="helper" className="InputLabel">
							{Strings.staff.helper}
						</label>
						<Select
							id="helper"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={helper || null}
							disabled={!admins}
							onChange={(value: any) => {
								this.setState({ helper: value, hasUnsavedFields: true });
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="helper2" className="InputLabel">
							{Strings.staff.helper2}
						</label>
						<Select
							id="helper2"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper2}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={helper2 || null}
							disabled={!admins}
							onChange={(value: any) => {
								this.setState({ helper2: value, hasUnsavedFields: true });
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12} lg={6}>
						<label htmlFor="helper3" className="InputLabel">
							{Strings.staff.helper3}
						</label>
						<Select
							id="helper3"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper3}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={helper3 || null}
							disabled={!admins}
							onChange={(value: any) => {
								this.setState({ helper3: value, hasUnsavedFields: true });
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24} md={12} lg={4}>
						<label htmlFor="customerNumber" className={`InputLabel`}>
							{Strings.worksheets.customerNumber}
						</label>
						<Input
							id="customerNumber"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.customerNumber}
							value={customerNumber}
							onChange={(elem: any) =>
								this.setState({ customerNumber: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					<Col xs={24} md={12} lg={4}>
						<label htmlFor="vehicleNumber" className={`InputLabel`}>
							{Strings.worksheets.vehicleNumber}
						</label>
						<Input
							id="vehicleNumber"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.vehicleNumber}
							disabled={!admins}
							value={vehicleNumber}
							onChange={(elem: any) =>
								this.setState({ vehicleNumber: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					<Col xs={24} md={12} lg={4}>
						<label htmlFor="vehicle" className={`InputLabel ${status !== 'validation' ? "--label-required" : ""}`}>
							{Strings.vehicles.vehicle}
						</label>
						<Select
							id="vehicle"
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.vehicles.vehicle}
							showSearch
							allowClear
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={vehicle || null}
							disabled={!admins || withoutVehicle}
							onChange={(elem: any) => {
								let vehicle;
								if (status !== "pending") {
									vehicle = vehicles.find((v: any) => v._id === elem);
								}

								console.log("vehicle", vehicle);

								this.setState({
									vehicle: elem,
									hasUnsavedFields: true,
									exitKm: vehicle ? vehicle?.lastUsageKm : null
								});
							}}
						>
							{vehicles.map((vehicle: any, index: number) => (
								<Select.Option key={`vehicle_${index}`} value={vehicle._id}>
									{vehicle.licensePlate}
								</Select.Option>
							))}
						</Select>
					</Col>
					{(Boolean(status) && status !== "pending" && status !== 'validation' && (
						<Col xs={24} md={12} lg={4}>
							<label htmlFor="exit_hour" className="InputLabel --label-required">
								{Strings.worksheets.exitHour}
							</label>
							<TimePicker
								id="exit_hour"
								allowClear={false}
								format="HH:mm"
								style={{ width: "100%", height: 40 }}
								value={exitHour || null}
								disabled={!withoutVehicle && isHelper}
								onChange={(value: any) => {
									this.setState({ exitHour: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
					)) ||
						null}
					{(Boolean(status) && status !== "pending" && status !== 'validation' && !withoutVehicle && (
						<Col xs={24} md={12} lg={4}>
							<label htmlFor="vehicle_km" className="InputLabel --label-required">
								{Strings.vehicles.kms}
							</label>
							<Input
								id="vehicle_km"
								className="tagsSelector"
								disabled={!vehicle || isHelper}
								type="number"
								min={0}
								style={{ width: "100%", height: 40 }}
								placeholder={Strings.vehicles.kms}
								value={exitKm}
								onChange={(elem: any) =>
									this.setState({ exitKm: elem.target.value, hasUnsavedFields: true })
								}
							/>
						</Col>
					)) ||
						null}
					<Col xs={24} md={24} lg={24}>
						<label htmlFor="description" className="InputLabel">
							{Strings.fields.description}
						</label>
						<Input.TextArea
							id="description"
							className="tagsSelector"
							placeholder={Strings.fields.description}
							value={description}
							disabled={isHelper}
							rows={4}
							onChange={(elem: any) =>
								this.setState({ description: elem.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
					{admins && typeof file === "string" ? (
						<Col xs={24} xl={18}>
							<label htmlFor="file_url" className="InputLabel --label-required">
								{Strings.fields.file}
							</label>
							<Input
								id="file_url"
								style={{ height: 40 }}
								value={file || ""}
								placeholder={Strings.fields.file}
								readOnly
								suffix={
									file?.includes("/worksheet/") ? (
										<span
											onClick={(e: React.MouseEvent) => {
												e.preventDefault();
												e.stopPropagation();
												this.setState({ file: null, hasUnsavedFields: true })
											}}
										>
											<CloseOutlined translate={null} />
										</span>
									) : null
								}
							/>
						</Col>
					) : admins && (
						<Col xs={24} xl={24}>
							<Dropzone
								accept="application/pdf"
								className="FilePdfDropzone"
								onDrop={(files: File[]) => this.onDropFile(files)}
							>
								<label htmlFor="file" className="InputLabel --label-required">
									{Strings.fields.file}
								</label>
								<Input
									id="file"
									style={{ height: 40, cursor: "pointer" }}
									value={file
										? typeof file === "string"
											? file
											: file.name : null}
									readOnly
									placeholder={Strings.settings.selectPdf}
									suffix={
										file?.name ? (
											<span
												onClick={(e: React.MouseEvent) => {
													e.preventDefault();
													e.stopPropagation();
													this.setState({ file: null, hasUnsavedFields: true })
												}}
											>
												<CloseOutlined translate={null} />
											</span>
										) : (
											<UploadOutlined translate={null} />
										)
									}
								/>
							</Dropzone>
						</Col>
					)}
					{file && admins && <Col xs={24} xl={6}>
						<Button
							id="file"
							type="primary"
							value={file}
							onClick={() => {window.open(file)}}
							style={{ backgroundColor: "#22a8dd", borderColor: "#22a8dd", width: "100%", marginTop: 29 }}
							
						>{Strings.fields.openFile}</Button>
					</Col>}
				</Row>
			</ContentWrapper>
		);
	}

	renderStatus() {
		const { isNew, status, withoutVehicle, vehicle, vehicles, date, customer, driver, exitHour, exitKm, helper, helper2, helper3 } = this.state;
		const { user } = this.props;

		const isHelper = user._id === helper || user._id === helper2 || user._id === helper3;
		const isAdmin = user?.role !== 'driver' && user?.role !== 'helper';

		if (isNew || (isHelper && !withoutVehicle)) return null;

		return (
			<Row>
				<Col xs={24} md={{ span: 12, offset: 12 }} xl={{ span: 8, offset: 16 }}>
					<div className="SegmentedControlContainer">
						{isAdmin && <button
							onClick={() => this.setState({ status: "validation", hasUnsavedFields: true })}
							className={`SegmentedControlOption${status === "validation" ? " --selected" : ""}`}
						>
							{Strings.worksheets.validation}
						</button>}
						<button
							onClick={() => this.setState({ status: "pending", hasUnsavedFields: true })}
							className={`SegmentedControlOption${status === "pending" ? " --selected" : ""}`}
						>
							{Strings.worksheets.pending}
						</button>
						<button
							onClick={() => {
								if (!date || !customer || (!withoutVehicle && (!driver || !vehicle))) {
									return notification.warn({
										message: Strings.worksheets.worksheet,
										description: Strings.worksheets.fillFields,
										placement: "bottomRight",
										duration: 5
									});
								}

								let tempVehicle;
								if (vehicle) {
									tempVehicle = vehicles.find((v: any) => v._id === vehicle);
								}

								this.setState({
									status: "started",
									exitKm: tempVehicle ? tempVehicle?.lastUsageKm : null,
									hasUnsavedFields: true
								});
							}}
							className={`SegmentedControlOption${status === "started" ? " --selected" : ""}`}
						>
							{Strings.worksheets.started}
						</button>
						<button
							onClick={() => {
								if (!date || !customer || !exitHour || (!withoutVehicle && (!driver || !vehicle || !exitKm))) {
									return notification.warn({
										message: Strings.worksheets.worksheet,
										description: Strings.worksheets.fillFields,
										placement: "bottomRight",
										duration: 5
									});
								}

								this.setState((prevState: any) => ({
									status: "finished",
									hasUnsavedFields: true,
									returnHour: prevState?.worksheet?.returnHour
										? moment.utc(prevState.worksheet.returnHour).local()
										: moment()
								}));
							}}
							className={`SegmentedControlOption${status === "finished" ? " --selected" : ""}`}
						>
							{Strings.worksheets.finished}
						</button>
					</div>
				</Col>
			</Row>
		);
	}

	renderRefuels() {
		const { refuels = [], status, isNew, worksheet } = this.state;
		const { user } = this.props;

		const isHelper = user.role === 'helper';

		if (!status || status === "validation" || worksheet?.status === "validation" || status === "pending" || worksheet?.status === "pending" || isNew) return null;

		return (
			<Table
				title={{
					icon: "expense",
					title: Strings.worksheets.refuels
				}}
				data={refuels}
				columns={[
					{
						Header: Strings.vehicles.kms,
						id: "km",
						accessor: (row: any) => row.km || "-"
					},
					{
						Header: Strings.worksheets.amount,
						id: "value",
						accessor: (row: any) => (row.value && `${row.value} €`) || "-"
					},
					{
						Header: Strings.worksheets.liters,
						id: "liters",
						accessor: (row: any) => row.liters || "-"
					},
					{
						Header: Strings.worksheets.amountAdBlue,
						id: "valueAdBlue",
						accessor: (row: any) => (row.valueAdBlue && `${row.valueAdBlue} €`) || "-"
					},
					{
						Header: Strings.worksheets.litersAdBlue,
						id: "litersAdBlue",
						accessor: (row: any) => row.litersAdBlue || "-"
					},
					{
						Header: Strings.worksheets.notes,
						id: "notes",
						accessor: (row: any) => row.notes || "-"
					}
				]}
				fullPage
				paginated={false}
				add={!isHelper && {
					onClick: () => this.setState({ tempRefuel: {}, showDrawerRefuel: true })
				}}
				actions={!isHelper && {
					edit: (original: any, row: any) => ({
						onClick: () => {
							this.setState({
								tempRefuel: { ...JSON.parse(JSON.stringify(original)), index: row.index },
								showDrawerRefuel: true
							});
						}
					}),
					remove: (original: any) => ({
						onClick: () => {
							const refuelIndex = refuels.findIndex((c: any) => c._id === original._id);
							if (refuelIndex > -1) {
								refuels.splice(refuelIndex, 1);
							}

							this.setState({ refuels });
						}
					})
				}}
			/>
		);
	}

	renderCosts() {
		const { costs = [], status, isNew, worksheet } = this.state;
		const { user } = this.props;

		const isHelper = user.role === 'helper';

		if (!status || status === "validation" || worksheet?.status === "validation" || status === "pending" || worksheet?.status === "pending" || isNew) return null;

		return (
			<Table
				title={{
					icon: "expense",
					title: Strings.worksheets.costs
				}}
				data={costs}
				columns={[
					{
						Header: Strings.vehicles.type,
						id: "type",
						accessor: (row: any) => this.getType(row.type),
						maxWidth: 120
					},
					{
						Header: Strings.fields.description,
						id: "description",
						accessor: (row: any) => row.description || "-"
					},
					{
						Header: Strings.worksheets.entry,
						id: "entry",
						accessor: (row: any) => row.entry || "-"
					},
					{
						Header: Strings.worksheets.exit,
						id: "exit",
						accessor: (row: any) => row.exit || "-"
					},
					{
						Header: Strings.worksheets.amount,
						id: "value",
						accessor: (row: any) => (row.value && `${row.value} €`) || "-"
					},
					{
						Header: Strings.worksheets.notes,
						id: "notes",
						accessor: (row: any) => row.notes || "-"
					}
				]}
				fullPage
				paginated={false}
				add={!isHelper && {
					onClick: () => this.setState({ tempExpense: {}, showDrawer: true })
				}}
				actions={!isHelper && {
					edit: (original: any, row: any) => ({
						onClick: () => {
							this.setState({
								tempExpense: { ...JSON.parse(JSON.stringify(original)), index: row.index },
								showDrawer: true
							});
						}
					}),
					remove: (original: any) => ({
						onClick: () => {
							const costIndex = costs.findIndex((c: any) => c._id === original._id);
							if (costIndex > -1) {
								costs.splice(costIndex, 1);
							}

							this.setState({ costs });
						}
					})
				}}
			/>
		);
	}
	
	renderExtraWorkStaff() {
		const { extraWorkValues = [], status, isNew, worksheet } = this.state;
		const { user } = this.props;

		const isAdmin = user?.role === "sysadmin" || user?.role === "admin";

		if (!status || status === "validation" || worksheet?.status === "validation" || isNew || !isAdmin) return null;

		return (
			<Table
				title={{
					icon: "expense",
					title: Strings.worksheets.extraWorkValue
				}}
				data={extraWorkValues}
				columns={[
					{
						Header: Strings.staff.staff,
						id: "staff",
						accessor: (row: any) => this.getStaff(row.staff),
						maxWidth: 300
					},
					{
						Header: Strings.worksheets.amount,
						id: "value",
						accessor: (row: any) => (row.value && `${row.value} €`) || "-"
					},
				]}
				fullPage
				paginated={false}
				add={isAdmin && {
					onClick: () => this.setState({ tempExtraWork: {}, showDrawerExtraWork: true })
				}}
				actions={isAdmin && {
					edit: (original: any, row: any) => ({
						onClick: () => {
							this.setState({
								tempExtraWork: { ...JSON.parse(JSON.stringify(original)), index: row.index },
								showDrawerExtraWork: true
							});
						}
					}),
					remove: (original: any) => ({
						onClick: () => {
							const costIndex = extraWorkValues.findIndex((c: any) => c._id === original._id);
							if (costIndex > -1) {
								extraWorkValues.splice(costIndex, 1);
							}

							this.setState({ extraWorkValues });
						}
					})
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer, tempExpense, costs, costTypes = [] } = this.state;
		const { mobile } = this.props;

		const isToll = costTypes?.find((costType: any) => costType?._id === tempExpense?.type)?.isToll;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="expenses" />
						<p>{Strings.settings.expensesTypes}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								if (((!isToll && tempExpense?.description) || (isToll && tempExpense?.entry && tempExpense?.exit)) && tempExpense?.type) {
									if (tempExpense.index == null) {
										costs.push(tempExpense);
									} else {
										costs[tempExpense.index] = tempExpense;
									}
									this.setState({ costs, hasUnsavedFields: true, showDrawer: false });
								} else {
									notification.warn({
										message: Strings.worksheets.worksheet,
										description: Strings.worksheets.fillFields,
										placement: "bottomRight",
										duration: 5
									});
								}
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempExpense: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ showDrawer: false, tempExpense: null })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempExpense, costTypes = [] } = this.state;

		const isToll = costTypes?.find((costType: any) => costType?._id === tempExpense?.type)?.isToll;

		return (
			<React.Fragment>
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="expense_cost_type" className="InputLabel --label-required">
							{Strings.worksheets.costType}
						</label>
						<Select
							id="expense_cost_type"
							key={`role_${tempExpense?.type}`}
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.worksheets.costType}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempExpense?.type || null}
							onChange={(elem: any) =>
								this.setState((state: any) => ({
									tempExpense: { ...state.tempExpense, type: elem }
								}))
							}
						>
							{costTypes.map((t: any) => (
								<Select.Option key={`type_${t._id}`} value={t._id}>
									{translate(t.description)}
								</Select.Option>
							))}
						</Select>
					</Col>
					{!isToll && <Col xs={24}>
						<label htmlFor="expense_description" className="InputLabel --label-required">
							{Strings.fields.description}
						</label>
						<Input
							id="expense_description"
							value={tempExpense?.description || ""}
							placeholder={Strings.fields.description}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempExpense: {
										...state.tempExpense,
										description: value
									}
								}));
							}}
						/>
					</Col>}
					<Col xs={24}>
						<label htmlFor="expense_value" className="InputLabel">
							{Strings.worksheets.amount}
						</label>
						<Input
							id="expense_value"
							type="number"
							value={tempExpense?.value != null ? tempExpense.value : null}
							placeholder={Strings.worksheets.amount}
							addonAfter="€"
							onChange={(e: any) => {
								const value = e.target.value;
								this.setState((state: any) => ({
									tempExpense: {
										...state.tempExpense,
										value
									}
								}));
							}}
						/>
					</Col>
					{isToll && <Col xs={24}>
						<label htmlFor="expense_entry" className="InputLabel --label-required">
							{Strings.worksheets.entry}
						</label>
						<Input
							id="expense_entry"
							value={tempExpense?.entry || ""}
							placeholder={Strings.worksheets.entry}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempExpense: {
										...state.tempExpense,
										entry: value
									}
								}));
							}}
						/>
					</Col>}
					{isToll && <Col xs={24}>
						<label htmlFor="expense_exit" className="InputLabel --label-required">
							{Strings.worksheets.exit}
						</label>
						<Input
							id="expense_entry"
							value={tempExpense?.exit || ""}
							placeholder={Strings.worksheets.exit}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempExpense: {
										...state.tempExpense,
										exit: value
									}
								}));
							}}
						/>
					</Col>}
					<Col xs={24}>
						<label htmlFor="expense_notes" className="InputLabel">
							{Strings.worksheets.notes}
						</label>
						<Input.TextArea
							id="expense_notes"
							value={tempExpense?.notes || ""}
							placeholder={Strings.worksheets.notes}
							rows={4}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempExpense: {
										...state.tempExpense,
										notes: value
									}
								}));
							}}
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}

	renderDrawerExtraWork() {
		const { showDrawerExtraWork, tempExtraWork, extraWorkValues } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="expenses" />
						<p>{Strings.worksheets.extraWork}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								if (tempExtraWork.index == null) {
									extraWorkValues.push(tempExtraWork);
								} else {
									extraWorkValues[tempExtraWork.index] = tempExtraWork;
								}
								this.setState({ extraWorkValues, hasUnsavedFields: true, showDrawerExtraWork: false });
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawerExtraWork: false, tempExtraWork: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ showDrawerExtraWork: false, tempExtraWork: null })}
				visible={showDrawerExtraWork}
			>
				{this.renderDrawerExtraWorkContent()}
			</Drawer>
		);
	}

	renderDrawerExtraWorkContent() {
		const { tempExtraWork, helpers = [], driver, helper, helper2, helper3 } = this.state;

		// eslint-disable-next-line array-callback-return
		const staffOnWorksheet = helpers.filter((staff: any) => {
			if (driver && driver === staff?._id) return staff;
			if (helper && helper === staff?._id) return staff;
			if (helper2 && helper2 === staff?._id) return staff;
			if (helper3 && helper3 === staff?._id) return staff;	
		})

		return (
			<React.Fragment>
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="expense_cost_type" className="InputLabel --label-required">
							{Strings.staff.staff}
						</label>
						<Select
							id="expense_cost_type"
							key={`role_${tempExtraWork?.staff}`}
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.staff.staff}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempExtraWork?.staff || null}
							onChange={(elem: any) =>
								this.setState((state: any) => ({
									tempExtraWork: { ...state.tempExtraWork, staff: elem }
								}))
							}
						>
							{staffOnWorksheet?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_value" className="InputLabel">
							{Strings.worksheets.amount}
						</label>
						<Input
							id="expense_value"
							type="number"
							value={tempExtraWork?.value != null ? tempExtraWork.value : null}
							placeholder={Strings.worksheets.amount}
							addonAfter="€"
							onChange={(e: any) => {
								const value = e.target.value;
								this.setState((state: any) => ({
									tempExtraWork: {
										...state.tempExtraWork,
										value
									}
								}));
							}}
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}

	renderDrawerRefuel() {
		const { showDrawerRefuel, tempRefuel, refuels, exitKm } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="expenses" />
						<p>{Strings.settings.expensesTypes}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								if (tempRefuel?.km != null) {
									if (exitKm < tempRefuel?.km) {
										if (tempRefuel.index == null) {
											refuels.push(tempRefuel);
										} else {
											refuels[tempRefuel.index] = tempRefuel;
										}

										this.setState({ refuels, hasUnsavedFields: true, showDrawerRefuel: false });
									} else {
										notification.warn({
											message: Strings.worksheets.worksheet,
											description: Strings.worksheets.invalidRefuelKm,
											placement: "bottomRight",
											duration: 5
										});
									}
								} else {
									notification.warn({
										message: Strings.worksheets.worksheet,
										description: Strings.worksheets.fillFields,
										placement: "bottomRight",
										duration: 5
									});
								}
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawerRefuel: false, tempRefuel: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ showDrawerRefuel: false, tempRefuel: null })}
				visible={showDrawerRefuel}
			>
				{this.renderDrawerRefuelContent()}
			</Drawer>
		);
	}

	renderDrawerRefuelContent() {
		const { tempRefuel } = this.state;

		return (
			<React.Fragment>
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="expense_value" className="InputLabel --label-required">
							{Strings.vehicles.kms}
						</label>
						<Input
							id="refuel_km"
							type="number"
							value={tempRefuel?.km != null ? tempRefuel.km : null}
							placeholder={Strings.vehicles.kms}
							onChange={(e: any) => {
								const km = e.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										km
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_value" className="InputLabel">
							{Strings.worksheets.amount}
						</label>
						<Input
							id="refuel_value"
							type="number"
							value={tempRefuel?.value != null ? tempRefuel.value : null}
							placeholder={Strings.worksheets.amount}
							addonAfter="€"
							onChange={(e: any) => {
								const value = e.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										value
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_value" className="InputLabel --label-required">
							{Strings.worksheets.liters}
						</label>
						<Input
							id="refuel_liters"
							type="number"
							value={tempRefuel?.liters != null ? tempRefuel.liters : null}
							placeholder={Strings.worksheets.liters}
							onChange={(e: any) => {
								const liters = e.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										liters
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_valueAdBlue" className="InputLabel">
							{Strings.worksheets.amountAdBlue}
						</label>
						<Input
							id="refuel_valueAdBlue"
							type="number"
							value={tempRefuel?.valueAdBlue != null ? tempRefuel.valueAdBlue : null}
							placeholder={Strings.worksheets.amountAdBlue}
							addonAfter="€"
							onChange={(e: any) => {
								const valueAdBlue = e.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										valueAdBlue
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_valueAdBlue" className="InputLabel">
							{Strings.worksheets.litersAdBlue}
						</label>
						<Input
							id="refuel_litersAdBlue"
							type="number"
							value={tempRefuel?.litersAdBlue != null ? tempRefuel.litersAdBlue : null}
							placeholder={Strings.worksheets.litersAdBlue}
							onChange={(e: any) => {
								const litersAdBlue = e.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										litersAdBlue
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="expense_notes" className="InputLabel">
							{Strings.worksheets.notes}
						</label>
						<Input.TextArea
							id="refuel_notes"
							value={tempRefuel?.notes || ""}
							placeholder={Strings.worksheets.notes}
							rows={4}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempRefuel: {
										...state.tempRefuel,
										notes: value
									}
								}));
							}}
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}

	render() {
		const { isNew, customer, date, withoutVehicle } = this.state;
		const { user } = this.props;

		const isAdmin = user?.role !== 'driver' && user?.role !== 'helper';

		return (
			<React.Fragment>
				<Helmet>
					<title>
						{Strings.worksheets.worksheets} -{" "}
						{isNew
							? Strings.worksheets.new
							: `${customer?.name ? `${customer?.name} - ` : ""}${
									date && moment.utc(date).format("DD/MM/YYYY")
							  }`}
					</title>
					<meta name="description" content="Description of Worksheet" />
				</Helmet>
				<div className="WorksheetScreen" style={isAdmin ? { paddingBottom: 80 } : {}}>
					{this.renderStatus()}
					{this.renderInfo()}
					{this.renderDeparture()}
					{this.renderReturn()}
					{!withoutVehicle && this.renderRefuels()}
					{!withoutVehicle && this.renderCosts()}
					{isAdmin && this.renderExtraWorkStaff()}
				</div>
				{this.renderDrawer()}
				{this.renderDrawerExtraWork()}
				{this.renderDrawerRefuel()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user,
	mobile: state.mobile
});

export default connect(mapStateToProps)(WorksheetDetail);
