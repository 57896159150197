import React, { Component } from "react";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Icon, Table } from "components";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb, setDailyViewFilters } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Checkbox,Col, Drawer, Input, notification, Row, Select, DatePicker, Tooltip, Modal, Switch, Button } from "antd";
import { DateTime } from "luxon";
import moment from "moment";
import Dropzone from "react-dropzone";

class DailyView extends Component<any, any> {
	constructor(props: any) {
		super(props);

		const { dailyViewFilters } = this.props;

		this.state = {
			needDuplicateEvaluation: false,
			isFirstTime: true,
			showDrawer: false,
			worksheet: [],
			duplicatedVehicles: [],
			duplicatedStaff: [],
			existPendingValidation: false,
			openConfirmValidationModal: false,
			validationType: null,
			worksheetsToClone: [],
			date: dailyViewFilters?.date ? moment.utc(dailyViewFilters.date) : moment().startOf("day"),
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		delayedDispatch(
			setBreadcrumb(() => {
				let { date } = this.state;
				
				return {
					actions: [
						{
							type: "singlePicker",
							text: Strings.fields.date,
							date,
							onChange: this.handleSelect,
							className: "fixedPicker",
							separator: 'right',
						},
						{
							type: "button",
							text: Strings.generic.clone,
							onClick: () => this.setState({ openConfirmCloneModal: true }),
							disabled: !this.state.worksheetsToClone?.length,
							className: this.state.existPendingValidation?.length ? "BreadcrumbButtonSuccess" : "",
							separator: 'right',
						},
						{
							type: "button",
							text: Strings.worksheets.validateAll,
							onClick: () => this.setState({ openConfirmValidationModal: true, validationType: "all" }),
							disabled: !this.state.existPendingValidation,
							className: this.state.existPendingValidation ? "BreadcrumbButtonSuccess" : "",
						}
					]
				};
			})
		);

		this.getData(true);
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	getData = async (notToWait: any = false) => {
		const { date, isFirstTime } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		setTimeout(
			async () => {
				dispatch(setLoader(true));

				try {
					const promises = [
						API.get({ url: Endpoints.uriWorksheets(`daily-view?date=${date.format("DD/MM/YYYY")}`) }),
					];
		
					
					if (isFirstTime) {
						promises.push(
							API.get({
								url: Endpoints.uriWorksheets("general-info")
							})
						);
					}

					const [response, responseGeneral] = await Promise.all(promises);

					if (response.ok) {
						const { worksheet, availableStaff } = response.data.results || [];
						const existPendingValidation = !!worksheet?.find((elem: any) => elem?.status === 'validation');

						this.setState({ worksheet, availableStaff, needDuplicateEvaluation: true, isFirstTime: false, showDrawer: false, existPendingValidation });
					}
		
					
					if (responseGeneral?.ok) {
						const {
							customers: apiCustomers = [],
							vehicles: apiVehicles = [],
							drivers: apiDrivers = [],
							helpers: apiHelpers = [],
						} = responseGeneral.data.results || {};
						
						this.setState({
							customers: apiCustomers,
							vehicles: apiVehicles,
							drivers: apiDrivers,
							helpers: apiHelpers
						});
					}
		
				} catch (err) {
					console.log("API Error", err);
					notification.error({
						message: Strings.serverErrors.title,
						description: (err as string) || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			notToWait ? 0 : 500
		);
	};

	async removeWorksheet(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriWorksheets(id)
			});

			if (response.ok) {
				await this.getData(true);

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}
	
	async validateWorksheet(id: string) {
		const { date } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const body = { date } as any;

			const response = await API.patch({
				url: Endpoints.uriWorksheets(id),
				data: body
			});

			if (response.ok) {
				this.setState({ openConfirmValidationModal: false, validationType: null })
				
				await this.getData(true);

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async cloneWorksheet() {
		const { cloneDate, worksheetsToClone } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const body = { 
				date: cloneDate,
				worksheetsToClone,
			} as any;

			const response = await API.patch({
				url: Endpoints.uriWorksheets('clone'),
				data: body
			});

			if (response.ok) {
				this.setState({ openConfirmCloneModal: false, worksheetsToClone: [], cloneDate: null })
				
				await this.getData(true);

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitWorksheet() {
		const {
			tempWorksheet,
			date,
		} = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const request = !tempWorksheet?._id ? API.post : API.put;

			const body = new FormData();

			body.append("customerNumber", tempWorksheet?.customerNumber || '')
			body.append("vehicleNumber", tempWorksheet?.vehicleNumber || '')
			body.append("withoutVehicle", tempWorksheet?.withoutVehicle || false)
			body.append("vehiclesNumber", tempWorksheet?.vehiclesNumber || 0)
			body.append("internalWork", tempWorksheet?.internalWork || false)
			body.append("workValue", tempWorksheet?.workValue || 0)
			body.append("workValueExtra", tempWorksheet?.workValueExtra || 0)
			body.append("extraWork", tempWorksheet?.extraWork || false)
			body.append("extraWorkValue", tempWorksheet?.extraWorkValue || 0)
			body.append("customer", tempWorksheet?.customer?._id || tempWorksheet?.customer || null)
			body.append("vehicle", tempWorksheet?.vehicle?._id || tempWorksheet?.vehicle || null)
			body.append("driver", tempWorksheet?.driver?._id || tempWorksheet?.driver || null)
			body.append("helper", tempWorksheet?.helper?._id || tempWorksheet?.helper || null)
			body.append("helper2", tempWorksheet?.helper2?._id || tempWorksheet?.helper2 || null)
			body.append("helper3", tempWorksheet?.helper3?._id || tempWorksheet?.helper3 || null)
			body.append("origin", tempWorksheet?.origin || null)
			body.append("destination", tempWorksheet?.destination || null)
			body.append("description", tempWorksheet?.description || null)
			body.append("status", tempWorksheet?._id ? tempWorksheet?.status : "validation")
			body.append("date", tempWorksheet?.date ? tempWorksheet?.date : date)


			if (tempWorksheet?.file?.file) {
				body.append("file", tempWorksheet.file.file);
			} else {
				body.append("file", tempWorksheet?.file || null);
			}

			const response = await request({
				url: Endpoints.uriWorksheets(tempWorksheet?._id || ""),
				data: body
			});

			if (response.ok) {
				await this.getData(true);

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	handleSelect = (value: any) => {
		const { dispatch } = this.props;
		
		dispatch(setDailyViewFilters({ date: value }));

		this.setState(
			{ date: value },
			() => this.getData(true)
		);
	};

	getStatus(status: string) {
		if (status === "validation") return Strings.worksheets.validation;
		if (status === "pending") return Strings.worksheets.pending;
		if (status === "started") return Strings.worksheets.started;
		if (status === "finished") return Strings.worksheets.finished;
		return status;
	}

	renderStatus = (elem: any) => {
		const worksheet = elem.cell.row.original;

		if (elem.value !== "validation" || (!worksheet?.withoutVehicle && !worksheet?.driver && !worksheet?.vehicle) || (worksheet?.withoutVehicle && !worksheet?.helper))
			return (
				<div>
					<span>{this.getStatus(elem?.value)}</span>
				</div>
			);

		return (
			<Tooltip title={Strings.worksheets.sendValidation}>
				<div
					className="TableTag --tag-warning --tag-clickable"
					onClick={() => this.setState({ openConfirmValidationModal: elem.cell.row.original, validationType: "record" })}
				>
					<Icon name="arrow-up" className="resend" />
					<span>{Strings.worksheets.validation}</span>
				</div>
			</Tooltip>
		);
	};

	renderConfirmWorksheet = () => {
		const { openConfirmValidationModal, validationType, worksheet } = this.state;

		const existInvalidWorksheets = !!worksheet?.find((elem: any) => (!elem?.withoutVehicle && !elem?.vehicle && !elem?.driver) || (elem?.withoutVehicle && !elem?.helper));

		return (
			<Modal
				className="resendInvite"
				style={{ textAlign: "center" }}
				visible={openConfirmValidationModal}
				cancelText={Strings.generic.close}
				okText={Strings.worksheets.validate}
				onOk={() => {this.validateWorksheet(openConfirmValidationModal?._id)}}
				onCancel={() => this.setState({ openConfirmValidationModal: false, validationType: null })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 200 }}
			>
				<Icon name="paper-plane" style={{ fontSize: "50px" }} />
				<div className="title">
					{validationType === "all"
						? Strings.formatString(Strings.worksheets.sendValidationAll,existInvalidWorksheets ? "Existe inválidas" : "")
						: Strings.formatString(Strings.worksheets.sendValidation,existInvalidWorksheets ? "Existe inválidas" : "")
					}
				</div>
			</Modal>
		);
	};
	
	renderCloneWorksheet = () => {
		const { openConfirmCloneModal, cloneDate } = this.state;

		return (
			<Modal
				className="resendInvite"
				style={{ textAlign: "center" }}
				visible={openConfirmCloneModal}
				cancelText={Strings.generic.close}
				okText={Strings.generic.clone}
				onOk={() => {this.cloneWorksheet()}}
				onCancel={() => this.setState({ openConfirmCloneModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 200 }}
			>
				<Icon name="duplicate" style={{ fontSize: "50px" }} />
				<div className="UploadModal">
					<div className="UploadTitle"/>
					<div className="UploadBody">
						<label htmlFor="date" className="InputLabel --label-required">
							{Strings.worksheets.cloneDate}
						</label>
						<DatePicker
							id="date"
							monthCellRender={(currentDate: any) => {
								return currentDate.format("MMM");
							}}
							allowClear={false}
							format="DD-MM-YYYY"
							style={{ width: "50%", height: 40 }}
							value={cloneDate}
							onChange={(value: any) => {
								this.setState({ cloneDate: moment.utc(value).startOf("day") });
							}}
						/>
					</div>
				</div>
			</Modal>
		);
	};

	renderCheckDuplicateVehicle = (elem: any) => {
		const { duplicatedVehicles } = this.state;

		const recordDuplicated = duplicatedVehicles?.includes(elem?.cell?.value?._id) || false;

		return (
			<div style={recordDuplicated ? { color: '#c10f0f' } : { }}>
				<span>{elem?.cell?.value?.licensePlate}</span>
			</div>
		);
	};
	
	renderCheckDuplicateStaff = (elem: any) => {
		const { duplicatedStaff } = this.state;

		const recordDuplicated = duplicatedStaff?.includes(elem?.cell?.value?._id) || false;

		return (
			<div style={recordDuplicated ? { color: '#c10f0f' } : {}}>
				<span>{elem?.cell?.value?.name}</span>
			</div>
		);
	};

	renderDrawer() {
		const { showDrawer, tempWorksheet } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="worksheet" />
						<p>{`${Strings.worksheets.worksheet} - ${tempWorksheet?.number || "Nova"}`}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.submitWorksheet()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempWorksheet: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempWorksheet: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	onDropFile = async (files: any) => {
		try {
			this.setState((state: any) => ({
				tempWorksheet: {
					...state.tempWorksheet,
					file: files[files.length - 1]
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	renderDrawerContent() {
		const { 
			tempWorksheet,
			customers,
			vehicles,
			drivers,
			helpers,
			date,
		} = this.state;

		return (
			<React.Fragment>
				<Row gutter={[0, 10]}>
					<Col xs={24}>
                        <div
                            className={`General_ColorFul_Switch ${
                                tempWorksheet?.withoutVehicle ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.withoutVehicle}</span>
                            <Switch
                                className={`Switch ${
                                    tempWorksheet?.withoutVehicle ? "__active" : ""
                                }`}
                                checked={(tempWorksheet?.withoutVehicle) || false}
                                size="small"
                                onChange={(value: any) => {
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, withoutVehicle: !tempWorksheet?.withoutVehicle }}));
                                }}
                            />
                        </div>
                    </Col>
					<Col xs={24}>
						<label htmlFor="vehicles_number_value" className="InputLabel">
							{Strings.worksheets.vehiclesNumber}
						</label>
						<Input
							id="vehicles_number_value"
							type="number"
							value={tempWorksheet?.vehiclesNumber != null ? tempWorksheet.vehiclesNumber : null}
							placeholder={Strings.worksheets.vehiclesNumber}
							onChange={(e: any) => {
								const vehiclesNumber = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, vehiclesNumber }}));
							}}
						/>
					</Col>
					<Col xs={24}>
                        <div
                            className={`General_ColorFul_Switch ${
                                tempWorksheet?.internalWork ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.internalWork}</span>
                            <Switch
                                className={`Switch ${
                                    tempWorksheet?.internalWork ? "__active" : ""
                                }`}
                                checked={(tempWorksheet?.internalWork) || false}
                                size="small"
                                onChange={(value: any) => {
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, internalWork: !tempWorksheet?.internalWork }}));
                                }}
                            />
                        </div>
                    </Col>
					<Col xs={24}>
                        <div
                            className={`General_ColorFul_Switch ${
                                tempWorksheet?.extraWork ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.extraWork}</span>
                            <Switch
                                className={`Switch ${
                                    tempWorksheet?.extraWork ? "__active" : ""
                                }`}
                                checked={(tempWorksheet?.extraWork) || false}
                                size="small"
                                onChange={(value: any) => {
									this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, extraWork: !tempWorksheet?.extraWork }}));
                                }}
                            />
                        </div>
                    </Col>
					{tempWorksheet?.extraWork && <Col xs={24}>
						<label htmlFor="extra_work_value" className="InputLabel">
							{Strings.worksheets.extraWorkValue}
						</label>
						<Input
							id="extra_work_value"
							type="number"
							value={tempWorksheet?.extraWorkValue != null ? tempWorksheet.extraWorkValue : null}
							placeholder={Strings.worksheets.extraWorkValue}
							addonAfter="€"
							onChange={(e: any) => {
								const extraWorkValue = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, extraWorkValue }}));
							}}
						/>
					</Col>}
					<Col xs={24}>
						<label htmlFor="work_value" className="InputLabel">
							{Strings.worksheets.workValue}
						</label>
						<Input
							id="work_value"
							type="number"
							value={tempWorksheet?.workValue != null ? tempWorksheet.workValue : null}
							placeholder={Strings.worksheets.workValue}
							addonAfter="€"
							onChange={(e: any) => {
								const workValue = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, workValue }}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="work_value_extra" className="InputLabel">
							{Strings.worksheets.workValueExtra}
						</label>
						<Input
							id="work_value_extra"
							type="number"
							value={tempWorksheet?.workValueExtra != null ? tempWorksheet.workValueExtra : null}
							placeholder={Strings.worksheets.workValueExtra}
							addonAfter="€"
							onChange={(e: any) => {
								const workValueExtra = e.target.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, workValueExtra }}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="date" className="InputLabel --label-required">
							{Strings.fields.date}
						</label>
						<DatePicker
							id="date"
							monthCellRender={(currentDate: any) => {
								return currentDate.format("MMM");
							}}
							disabled={tempWorksheet?.status !== 'pending' && tempWorksheet?.status !== 'validation' && tempWorksheet?._id}
							allowClear={false}
							format="DD-MM-YYYY"
							style={{ width: "100%", height: 40 }}
							value={tempWorksheet?.date ? moment(tempWorksheet?.date).local() : date}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, date: value }}));
							}}
						/>
					</Col>
					<Col xs={24}>
						<label
							htmlFor="customer"
							className={`InputLabel`}
						>
							{Strings.customers.customer}
						</label>
						<Select
							id="customer"
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.customers.customer}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							allowClear
							value={tempWorksheet?.customer?._id || tempWorksheet?.customer || null}
							onChange={(elem: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, customer: elem }}));
							}}
						>
							{customers?.map((customer: any, index: number) => (
								<Select.Option key={`customer_${index}`} value={customer._id}>
									{customer.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="customerNumber" className="InputLabel">
							{Strings.worksheets.customerNumber}
						</label>
						<Input
							id="customerNumber"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.customerNumber}
							value={tempWorksheet?.customerNumber}
							onChange={(elem: any) => {
								const customerNumber = elem.target?.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, customerNumber }}))
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="vehicleNumber" className="InputLabel">
							{Strings.worksheets.vehicleNumber}
						</label>
						<Input
							id="vehicleNumber"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.vehicleNumber}
							value={tempWorksheet?.vehicleNumber}
							onChange={(elem: any) => {
								const vehicleNumber = elem.target?.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, vehicleNumber }}))
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="origin" className="InputLabel">
							{Strings.worksheets.origin}
						</label>
						<Input
							id="origin"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.origin}
							value={tempWorksheet?.origin}
							onChange={(elem: any) => {
								const origin = elem.target?.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, origin }}))
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="destination" className="InputLabel">
							{Strings.worksheets.destination}
						</label>
						<Input
							id="destination"
							className="tagsSelector"
							style={{ width: "100%", height: 40 }}
							placeholder={Strings.worksheets.destination}
							value={tempWorksheet?.destination}
							onChange={(elem: any) => {
								const destination = elem.target?.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, destination }}))
							}}
						/>
					</Col>
					<Col xs={24}>
						<label htmlFor="vehicle" className="InputLabel">
							{Strings.vehicles.vehicle}
						</label>
						<Select
							id="vehicle"
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.vehicles.vehicle}
							showSearch
							disabled={tempWorksheet?.withoutVehicle}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempWorksheet?.vehicle?._id || tempWorksheet?.vehicle || null}
							onChange={(elem: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, vehicle: elem }}));
							}}
						>
							{vehicles?.map((vehicle: any, index: number) => (
								<Select.Option key={`vehicle_${index}`} value={vehicle._id}>
									{vehicle.licensePlate}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="driver" className="InputLabel">
							{Strings.staff.driver}
						</label>
						<Select
							id="driver"
							className="tagsSelector"
							style={{ width: "100%" }}
							placeholder={Strings.staff.driver}
							showSearch
							disabled={tempWorksheet?.withoutVehicle}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempWorksheet?.driver?._id || tempWorksheet?.driver || null}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, driver: value }}));
							}}
						>
							{drivers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="driver" className="InputLabel">
							{Strings.staff.helper}
						</label>
						<Select
							id="helper"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempWorksheet?.helper?._id || tempWorksheet?.helper || null}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, helper: value }}));
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="driver" className="InputLabel">
							{Strings.staff.helper2}
						</label>
						<Select
							id="helper2"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper2}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempWorksheet?.helper2?._id || tempWorksheet?.helper2 || null}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, helper2: value }}));
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="driver" className="InputLabel">
							{Strings.staff.helper3}
						</label>
						<Select
							id="helper3"
							className="tagsSelector"
							style={{ width: "100%" }}
							allowClear
							placeholder={Strings.staff.helper3}
							showSearch
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={tempWorksheet?.helper3?._id || tempWorksheet?.helper3 || null}
							onChange={(value: any) => {
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, helper3: value }}));
							}}
						>
							{helpers?.map((s: any) => (
								<Select.Option key={s?._id} value={s._id}>
									{s?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col xs={24}>
						<label htmlFor="description" className="InputLabel">
							{Strings.fields.description}
						</label>
						<Input.TextArea
							id="description"
							className="tagsSelector"
							placeholder={Strings.fields.description}
							value={tempWorksheet?.description}
							rows={6}
							onChange={(elem: any) => {
								const description = elem.target?.value;
								this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, description }}))
							}}
						/>
					</Col>
					{typeof tempWorksheet?.file === "string" ? (
						<Col xs={24} xl={18}>
							<label htmlFor="file_url" className="InputLabel --label-required">
								{Strings.fields.file}
							</label>
							<Input
								id="file_url"
								style={{ height: 40 }}
								value={tempWorksheet?.file
									? typeof tempWorksheet.file === "string"
										? tempWorksheet.file
										: tempWorksheet.file.name : null}
								placeholder={Strings.fields.file}
								readOnly
								suffix={
									tempWorksheet?.file?.includes("/worksheet/") ? (
										<span
											onClick={(e: React.MouseEvent) => {
												e.preventDefault();
												e.stopPropagation();
												this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, file: null }}))
											}}
										>
											<CloseOutlined translate={null} />
										</span>
									) : null
								}
							/>
						</Col>
					) : (
						<Col xs={24} xl={24}>
							<Dropzone
								accept="application/pdf"
								className="FilePdfDropzone"
								onDrop={(files: File[]) => this.onDropFile(files)}
							>
								<label htmlFor="file" className="InputLabel --label-required">
									{Strings.fields.file}
								</label>
								<Input
									id="file"
									style={{ height: 40, cursor: "pointer" }}
									value={tempWorksheet?.file
										? typeof tempWorksheet.file === "string"
											? tempWorksheet.file
											: tempWorksheet.file.name : null}
									readOnly
									placeholder={Strings.settings.selectPdf}
									suffix={
										tempWorksheet?.file?.name ? (
											<span
												onClick={(e: React.MouseEvent) => {
													e.preventDefault();
													e.stopPropagation();
													this.setState((state: any) => ({ tempWorksheet: { ...state.tempWorksheet, file: null }}))
												}}
											>
												<CloseOutlined translate={null} />
											</span>
										) : (
											<UploadOutlined translate={null} />
										)
									}
								/>
							</Dropzone>
						</Col>
					)}
					{typeof tempWorksheet?.file === "string" && <Col xs={24} xl={6}>
						<Button
							id="file"
							type="primary"
							value={tempWorksheet?.file}
							onClick={() => {window.open(tempWorksheet?.file)}}
							style={{ backgroundColor: "#22a8dd", borderColor: "#22a8dd", width: "100%", marginTop: 29, marginLeft: 5 }}
							icon={<Icon name="duplicate" />}
						/>
					</Col>}
				</Row>
			</React.Fragment>
		);
	}

	getRole(role: string) {
		switch (role) {
			case "sysadmin":
				return Strings.staff.sysAdmin;
			case "admin":
				return Strings.staff.admin;
			case "mechanic":
				return Strings.staff.mechanic;
			case "driver":
				return Strings.staff.driver;
			case "helper":
				return Strings.staff.helper;
			default:
				return role;
		}
	}

	render() {
		const { worksheet, needDuplicateEvaluation, availableStaff, worksheetsToClone = [] } = this.state;
		const { dispatch } = this.props;

		if (needDuplicateEvaluation) {
			const { worksheet } = this.state;
			const duplicatedVehicles = [] as Array<any>;
			const duplicatedStaff = [] as Array<any>;

			const encounteredVehicleIds = new Set();
			const encounteredStaffIds = new Set();

			worksheet.forEach((item: any) => {
				const { vehicle, driver, helper, helper2, helper3 } = item;

				// Check for duplicate vehicles
				if (vehicle?._id && encounteredVehicleIds.has(vehicle?._id)) {
					duplicatedVehicles.push(vehicle._id);
				} else if (vehicle?._id) {
					encounteredVehicleIds.add(vehicle?._id);
				}

				// Check for duplicate staff members
				const staffIds = [driver?._id, helper?._id, helper2?._id, helper3?._id].filter(Boolean);
				staffIds.forEach(staffId => {
					if (encounteredStaffIds.has(staffId)) {
						duplicatedStaff.push(staffId);
					} else {
						encounteredStaffIds.add(staffId);
					}
				});
			})
			
			this.setState({ duplicatedVehicles, duplicatedStaff, needDuplicateEvaluation: false });
		}

		return (
			<React.Fragment>
				<div>
					<Helmet>
						<title>{Strings.worksheets.worksheets}</title>
						<meta name="description" content="Description of DailyView" />
					</Helmet>
					<Table
						title={{
							icon: "duplicate",
							title: Strings.worksheets.dailyView
						}}
						data={worksheet}
						columns={[
							{
								id: "selection",
								Cell: (cell: any) => {
									const id = cell.row?.original?._id || null;
									const checked = worksheetsToClone?.includes(id) || false;;
									const valid = cell.row?.original?.status !== 'validation'
			
									return (
										<Checkbox
											checked={checked}
											disabled={!valid}
											onClick={() => {
												if (checked) {
													const index = worksheetsToClone?.indexOf(id);
			
													worksheetsToClone?.splice(index, 1);
													this.setState({ worksheetsToClone });
												} else if (valid) {			
													worksheetsToClone?.push(id);
													this.setState({ worksheetsToClone });
												}
											}}
										/>
									);
								},
								maxWidth: 35,
							},
							{
								Header: Strings.fields.number,
								id: "number",
								minWidth: 50,
								accessor: (row: any) => row.number
							},
							{
								Header: Strings.customers.customer,
								id: "name",
								minWidth: 150,
								accessor: (row: any) => row.customer?.name || ""
							},
							{
								Header: Strings.worksheets.origin,
								id: "origin",
								minWidth: 150,
								accessor: (row: any) => row.origin
							},
							{
								Header: Strings.worksheets.destination,
								id: "destination",
								minWidth: 150,
								accessor: (row: any) => row.destination
							},
							{
								Header: Strings.vehicles.vehicle,
								id: "vehicle",
								minWidth: 50,
								accessor: (row: any) => row.vehicle,
								Cell: this.renderCheckDuplicateVehicle
							},
							{
								Header: Strings.staff.driver,
								id: "driver",
								minWidth: 150,
								accessor: (row: any) => row.driver,
								Cell: this.renderCheckDuplicateStaff
							},
							{
								Header: Strings.staff.helper,
								id: "helper",
								minWidth: 150,
								accessor: (row: any) => row.helper,
								Cell: this.renderCheckDuplicateStaff
							},
							{
								Header: Strings.staff.helper2,
								id: "helper2",
								minWidth: 150,
								accessor: (row: any) => row.helper2,
								Cell: this.renderCheckDuplicateStaff
							},
							{
								Header: Strings.staff.helper3,
								id: "helper3",
								minWidth: 150,
								accessor: (row: any) => row.helper3,
								Cell: this.renderCheckDuplicateStaff
							},
							{
								Header: Strings.worksheets.status,
								id: "status",
								width: 150,
								accessor: (row: any) => row.status,
								Cell: this.renderStatus
							},
							{
								Header: Strings.worksheets.exitHour,
								id: "exitHour",
								minWidth: 70,
								accessor: (row: any) =>
									(row.exitHour && DateTime.fromISO(row.exitHour).toFormat("HH:mm")) || "-"
							},
							{
								Header: Strings.worksheets.returnHour,
								id: "returnHour",
								minWidth: 70,
								accessor: (row: any) =>
									(row.returnHour && DateTime.fromISO(row.returnHour).toFormat("HH:mm")) || "-"
							},
						]}
						isSinglePage
						fullPage
						paginated={false}
						add={{
							onClick: () => this.setState({ showDrawer: true, tempWorksheet: {} })
						}}
						actions={{
							edit: (original: any) => ({
								onClick: () => {
									if (original?.status !== 'pending' && original?.status !== 'validation') {
										dispatch(push(`/worksheets/${original._id}`))
									} else {
										this.setState({ showDrawer: true, tempWorksheet: { ...original } })
									}
								}
							}),
							remove: (original: any) => ({
								onClick: () => this.removeWorksheet(original._id)
							}),
						}}
					/>
				</div>
				<div style={{ marginTop: 20, paddingBottom: 30 }}>
					<Helmet>
						<title>{Strings.worksheets.staffWithoutWork}</title>
						<meta name="description" content="Description of Staff Without Work" />
					</Helmet>
					<Table
						title={{
							icon: "user-group",
							title: Strings.worksheets.staffWithoutWork
						}}
						data={availableStaff}
						columns={[
							{
								Header: Strings.fields.photo,
								id: "photo",
								accessor: (row: any) => row.photo,
								type: "image",
								maxWidth: 65
							},
							{
								Header: Strings.fields.name,
								id: "name",
								accessor: (row: any) => row.name
							},
							{
								Header: Strings.fields.email,
								id: "email",
								accessor: (row: any) => row.email
							},
							{
								Header: Strings.fields.phone,
								id: "phone",
								accessor: (user: any) => String(user.phone)
							},
							{
								Header: Strings.profile.role,
								id: "role",
								accessor: (row: any) => this.getRole(row.role)
							},
						]}
						isSinglePage
						fullPage
						paginated={false}
					/>
				</div>
				{this.renderDrawer()}
				{this.renderConfirmWorksheet()}
				{this.renderCloneWorksheet()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	dailyViewFilters: state.dailyViewFilters
});

export default connect(mapStateToProps)(DailyView);
