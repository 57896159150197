// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SettingsCards{height:100%;padding:0;margin-bottom:0;transition:200ms}.SettingsCards:hover{box-shadow:0 1px 2px #85BA17,0 0 0 1px #85BA17}.SettingsCards:hover .SettingsOptionTitle,.SettingsCards:hover .SettingsOptionNavigate{color:#85ba17}.SettingsOptionBlock{display:flex;justify-content:center;height:100%;padding:20px 10px;cursor:pointer;position:relative;box-shadow:1px 1px 8px -2px rgba(0,0,0,.15)}.SettingsOptionBlock::before{position:absolute;left:0;top:0;content:\"\";width:3px;height:100%;background-color:#85ba17}.SettingsOptionBlock .SettingsIcon{display:flex;width:35px;margin:0 20px 0;font-size:2.6em;color:#85ba17}.SettingsOptionBlock .SettingsOptionText{display:flex;flex:1;flex-direction:column;min-height:inherit;height:inherit}.SettingsOptionBlock .SettingsOptionTitle{display:block;font-weight:500;margin-bottom:10px;font-size:14px}.SettingsOptionBlock .SettingsOptionSubTitle{font-size:.8em;color:#777}.SettingsOptionBlock .SettingsOptionNavigate{align-items:flex-end;display:flex;color:#a9a9a9;text-decoration:underline;margin-top:10px;font-size:12px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
