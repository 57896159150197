import React, { Component } from "react";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Table } from "components";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, setWorksheetsFilters, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { notification, Modal } from "antd";
import { DateTime } from "luxon";
import moment from "moment";
import xlsx from "json-as-xlsx";

class Worksheets extends Component<any, any> {
	constructor(props: any) {
		super(props);

		const { worksheetsFilters } = this.props;

		this.state = {
			worksheet: [],
			worksheetExported: [],
			page: worksheetsFilters?.page || 0,
			pageSize: worksheetsFilters?.pageSize || 100,
			search: worksheetsFilters?.search || "",
			filters: worksheetsFilters?.filters || {},
			startDate: worksheetsFilters?.startDate ? moment.utc(worksheetsFilters.startDate) : moment().add({ days: -7 }).startOf("day"),
			endDate: worksheetsFilters?.endDate ? moment.utc(worksheetsFilters.endDate) : moment().add({ days: 2 }).endOf("day"),
			total: 0,
			globalSearch: worksheetsFilters?.globalSearch || ""
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		delayedDispatch(
			setBreadcrumb(() => {
				const { startDate, endDate } = this.state;

				return {
					actions: [
						{
							type: "datePicker",
							text: Strings.dashboard.period,
							dates: [startDate, endDate],
							onChange: this.handleSelect,
							className: "fixedPicker"
						}
					]
				};
			})
		);

		this.getData(true);
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	getData = async (notToWait: any = false, exportWorksheets: any = false) => {
		const { page, pageSize, columnSearch, globalSearch, filters, startDate, endDate } = this.state;
		const { dispatch } = this.props;

		setTimeout(
			async () => {
				if (columnSearch !== this.state.columnSearch || globalSearch !== this.state.globalSearch) return;

				dispatch(setLoader(true));

				const bodyFilters = columnSearch
					? Object.keys(columnSearch).map((elem) =>
							columnSearch[elem] !== ""
								? {
										field: elem,
										query: columnSearch[elem]
								  }
								: {}
					  )
					: [];

				if (filters?.business) {
					bodyFilters.push({
						field: "business",
						query: filters.business
					});
				}

				const body = {
					filters: bodyFilters,
					page: page,
					perPage: pageSize,
					search: globalSearch,
					exportWorksheets: exportWorksheets,
				};

				try {
					const response = await API.post({
						url: Endpoints.uriWorksheets(
							`search?startDate=${startDate.format("DD/MM/YYYY")}&endDate=${endDate.format("DD/MM/YYYY")}`
						),
						data: body
					});

					if (response.ok) {
						const { worksheet, total = 0 } = response.data.results || {};
						if (exportWorksheets) {
							this.setState({ worksheetExported: worksheet });
							this.downloadRouteCsv();
						} else {
							this.setState({ worksheet, total });
						}
					}
				} catch (err) {
					console.log("API Error", err);
					notification.error({
						message: Strings.serverErrors.title,
						description: (err as string) || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				dispatch(setLoader(false));
			},
			notToWait ? 0 : 500
		);
	};

	async removeWorksheet(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriWorksheets(id)
			});

			if (response.ok) {
				await this.getData(true);

				notification.success({
					message: Strings.worksheets.worksheets,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	get pagination() {
		const { dispatch } = this.props;
		return {
			total: this.state.total,
			pageIndex: this.state.page,
			pageSize: this.state.pageSize,
			setPage: (page: number, size: number) => {
				dispatch(setWorksheetsFilters({ ...this.props.worksheetsFilters, page, pageSize: size }));
				this.setState({ page, pageSize: size }, () => this.getData(true));
			}
		};
	}

	get filtration() {
		const { dispatch } = this.props;
		const { globalSearch, columnSearch } = this.state;
		return {
			showGlobalSearch: true,
			showColumnSearch: true,
			defaultValues: { globalSearch, columnSearch },
			onGlobalSearch: (globalSearch: string) => {
				dispatch(setWorksheetsFilters({ ...this.props.worksheetsFilters, globalSearch, page: 0 }));
				this.setState({ globalSearch, page: 0 }, this.getData);
			},
			onColumnSearch: (columnSearch: string) => {
				dispatch(setWorksheetsFilters({ ...this.props.worksheetsFilters, columnSearch, page: 0 }));
				this.setState({ columnSearch, page: 0 }, this.getData);
			}
		};
	}

	handleSelect = (dates: any) => {
		const { dispatch } = this.props;

		const newDates = [];
		if (dates && dates[0]) {
			newDates.push(moment(dates[0]).startOf("day"));
		}

		if (dates && dates[1]) {
			newDates.push(moment(dates[1]).endOf("day"));
		}

		dispatch(setWorksheetsFilters({ ...this.props.worksheetsFilters, startDate: newDates[0], endDate: newDates[1] }));

		this.setState(
			{
				startDate: newDates[0],
				endDate: newDates[1]
			},
			() => this.getData(true)
		);
	};

	getStatus(status: string) {
		if (status === "validation") return Strings.worksheets.validation;
		if (status === "pending") return Strings.worksheets.pending;
		if (status === "started") return Strings.worksheets.started;
		if (status === "finished") return Strings.worksheets.finished;
		return status;
	}

	renderExport() {
        const { showExportModal } = this.state;
        
        return (
            <Modal
                centered
                width="500px"
                visible={!!showExportModal}
                cancelButtonProps={{ className: "displayNone" }}
                okButtonProps={{ className: "displayNone" }}
                onCancel={() => {
                    this.setState({ showExportModal: null });
                }}
				onOk={async () => {
					this.setState({ showExportModal: null });
					await this.getData(true, true);
				}}
            >
				<div className="title">
					Deseja realizar o download para excel das folhas de trabalho?
				</div>
            </Modal>
        );
    }

	downloadRouteCsv() {
		const { worksheetExported } = this.state;

		let content = worksheetExported?.map((row: any) => ({
			date: DateTime.fromISO(row.date).toFormat("dd/MM/yyyy"),
			number: row.number,
			customerNumber: row.customerNumber,
			internalWork: row.internalWork ? "True" : "False",
			extraWork: row.extraWork ? "True" : "False",
			withoutVehicle: row.withoutVehicle ? "True" : "False",
			vehicleNumber: row.vehicleNumber,
			vehiclesNumber: row.vehiclesNumber,
			extraWorkTotalValue: row.extraWorkTotalValue,
			workValue: row.workValue,
			workValueExtra: row.workValueExtra,
			driver: row.driver,
			helper: row.helper,
			helper2: row.helper2,
			helper3: row.helper3,
			vehicle: row.vehicle,
			customer: row.customer,
			status: this.getStatus(row.status) || "-",
			exitHour: (row.exitHour && DateTime.fromISO(row.exitHour).toFormat("HH:mm")) || "-",
			returnHour: (row.returnHour && DateTime.fromISO(row.returnHour).toFormat("HH:mm")) || "-",
			exitKm: row.exitKm || "-",
			returnKm: row.returnKm || "-",
			description: row.description,
		}));

		let data = [
			{
				sheet: "Worksheets",
				columns: [
					{ label: "Data", value: "date" },
					{ label: "Número", value: "number" },
					{ label: "Nº Rota", value: "customerNumber" },
					{ label: "Nº Carro", value: "vehicleNumber" },
					{ label: "Trabalho Interno", value: "internalWork" },
					{ label: "Trabalho Extra", value: "extraWork" },
					{ label: "Sem Viatura", value: "withoutVehicle" },
					{ label: "Nº Viaturas", value: "vehiclesNumber" },
					{ label: "Valor Extra (Staff)", value: "extraWorkTotalValue" },
					{ label: "Valor", value: "workValue" },
					{ label: "Valor Extra", value: "workValueExtra" },
					{ label: "Motorista", value: "driver" },
					{ label: "Ajudante", value: "helper" },
					{ label: "Ajudante2", value: "helper2" },
					{ label: "Ajudante3", value: "helper3" },
					{ label: "Viatura", value: "vehicle" },
					{ label: "Cliente", value: "customer" },
					{ label: "Estado", value: "status" },
					{ label: "Hora Início", value: "exitHour" },
					{ label: "Hora Fim", value: "returnHour" },
					{ label: "Km Início", value: "exitKm" },
					{ label: "Km Fim", value: "returnKm" },
					{ label: "Notas", value: "description" },
				],
				content: content,
			},
		];

		let settings = {
			fileName: `worksheets`,
			extraLength: 3,
			writeMode: "writeFile",
			writeOptions: {},
		};

		xlsx(data, settings);
	};


	render() {
		const { worksheet = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.worksheets.worksheets}</title>
					<meta name="description" content="Description of Worksheets" />
				</Helmet>
				<Table
					title={{
						icon: "duplicate",
						title: Strings.worksheets.worksheets
					}}
					data={worksheet}
					columns={[
						{
							Header: Strings.fields.date,
							id: "date",
							accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy"),
							minWidth: 50
						},
						{
							Header: Strings.fields.number,
							id: "number",
							minWidth: 50,
							accessor: (row: any) => row.number
						},
						{
							Header: Strings.staff.driver,
							id: "staff",
							minWidth: 150,
							accessor: (row: any) => row.driver
						},
						{
							Header: Strings.vehicles.vehicle,
							id: "vehicle",
							minWidth: 50,
							accessor: (row: any) => row.vehicle
						},
						{
							Header: Strings.customers.customer,
							id: "name",
							minWidth: 150,
							accessor: (row: any) => row.customer
						},
						{
							Header: Strings.worksheets.status,
							id: "status",
							minWidth: 70,
							accessor: (row: any) => this.getStatus(row.status) || "-"
						},
						{
							Header: Strings.worksheets.exitHour,
							id: "exitHour",
							minWidth: 70,
							accessor: (row: any) =>
								(row.exitHour && DateTime.fromISO(row.exitHour).toFormat("HH:mm")) || "-"
						},
						{
							Header: Strings.worksheets.returnHour,
							id: "returnHour",
							minWidth: 70,
							accessor: (row: any) =>
								(row.returnHour && DateTime.fromISO(row.returnHour).toFormat("HH:mm")) || "-"
						},
						{
							Header: Strings.worksheets.exitKm,
							id: "exitKm",
							minWidth: 50,
							accessor: (row: any) => row.exitKm || "-"
						},
						{
							Header: Strings.worksheets.returnKm,
							id: "returnKm",
							minWidth: 50,
							accessor: (row: any) => row.returnKm || "-"
						},
						{
							Header: Strings.worksheets.workValue,
							id: "workValue",
							align: 'right',
							minWidth: 50,
							accessor: (row: any) => (row.workValue && `${row.workValue} €`) || "-"
						},
						{
							Header: Strings.worksheets.workValueExtraShort,
							id: "workValueExtra",
							align: 'right',
							minWidth: 50,
							accessor: (row: any) => (row.workValueExtra && `${row.workValueExtra} €`) || "-"
						},
						{
							Header: Strings.worksheets.extraWorkValueShort,
							id: "extraWorkTotalValue",
							align: 'right',
							minWidth: 50,
							accessor: (row: any) => (row.extraWorkTotalValue && `${row.extraWorkTotalValue} €`) || "-"
						},
					]}
					filterable
					isSinglePage
					fullPage
					paginationApi={this.pagination}
					filtrationApi={this.filtration}
					headerOptions={[{
						label: Strings.generic.export,
						tooltip: Strings.generic.export,
						icon: "paper-plane",
						onClick: () => this.setState({ showExportModal: true }),
					}]}
					add={{
						onClick: () => dispatch(push("/worksheets/new"))
					}}
					actions={{
						edit: (original: any) => ({
							onClick: () => dispatch(push(`/worksheets/${original._id}`))
						}),
						remove: (original: any) => ({
							onClick: () => this.removeWorksheet(original._id)
						})
					}}
				/>
				{this.renderExport()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	worksheetsFilters: state.worksheetsFilters
});

export default connect(mapStateToProps)(Worksheets);
