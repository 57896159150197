// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderContainer{z-index:10;background:#fff;box-shadow:2px 3px 8px #dedcde;border-radius:0;padding:0px 25px}.Header{width:100%;display:flex;justify-content:space-between;align-items:center;height:62px;min-height:62px;position:relative}.HeaderUserMenu{display:flex;align-items:center;height:40px}.HeaderUserMenu:hover{color:#85ba17;background-color:transparent !important}.HeaderUserMenuIcon{font-size:16px;margin-right:10px}.HeaderUserOptions{display:flex;align-items:center;height:40px;min-width:150px;max-width:250px;padding:0 10px;border-radius:8px;background-color:#f5f5f5;box-shadow:0 0 4px 1px rgba(0,0,0,.2);user-select:none;cursor:pointer}.HeaderUserOptions:active{box-shadow:inset 0 0 5px #aaa}.HeaderLeftSection{display:flex;align-items:center;height:100%}.HeaderLeftSection .PageTitle{color:#777;font-size:20px;opacity:0}.HeaderLeftSection .PageTitle.__show{animation:show .2s forwards}.HeaderLeftSection .PageTitle.__hide{animation:hide .2s forwards}.BurgerContainer{display:flex;height:40px;width:40px;border-radius:50%;background-color:#333;align-items:center;justify-content:center;margin-right:20px;cursor:pointer}.BurgerContainer:hover .BurgerMenu{color:#22a8dd}.BurgerContainer:active .BurgerMenu{transform:scale(0.95)}.BurgerContainer .BurgerMenu{color:#fff;font-size:12px}.BurgerContainer .BurgerMenu.__bigger{font-size:20px}@keyframes show{0%{opacity:0;transform:translateX(-20px)}100%{opacity:1}}@keyframes hide{0%{opacity:1}100%{opacity:0;transform:translateX(20px)}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
