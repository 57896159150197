// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WorksheetScreen .WorksheetTitle{display:flex;align-items:center;margin-bottom:10px}.WorksheetScreen .WorksheetTitle p{color:#333;font-size:20px;margin:0}.WorksheetScreen .WorksheetTitle em{font-size:28px;margin-right:10px}.WorksheetScreen .SegmentedControlContainer{display:flex;align-items:center;margin-bottom:20px}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption{border-radius:0;border:1px solid #85ba17;flex:1;height:40px;background-color:#fff;font-weight:500;transition:200ms ease-in-out;cursor:pointer}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption:not(:first-child):not(:last-child){border-left:none;border-right:none}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption:first-child{border-radius:4px;border-top-right-radius:0;border-bottom-right-radius:0}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption:last-child{border-radius:4px;border-top-left-radius:0;border-bottom-left-radius:0}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption.--selected{background-color:#85ba17;color:#fff}.WorksheetScreen .SegmentedControlContainer .SegmentedControlOption:hover:not(.--selected){background-color:#eefad6}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
