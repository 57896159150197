import React, { Component } from "react";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Table, Icons } from "components";
import { setBreadcrumb, setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import { notification, Modal } from "antd";
import Strings from "utils/strings";
import Dropzone from "react-dropzone";

class Vehicles extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			vehicle: []
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriVehicles()
			});

			if (response.ok) {
				this.setState({ vehicle: response.data?.results?.vehicle || [] });
			}
		} catch (err) {
			console.log("API Error", err);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeVehicle(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriVehicles(id)
			});

			if (response.ok) {
				this.setState({ vehicle: response.data?.results?.vehicle, ...response.data?.results?.vehicle });

				notification.success({
					message: Strings.vehicles.vehicle,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async toggleVehicle(vehicle: any) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriVehicles(`${vehicle?._id}/status`),
				data: { isActive: !vehicle.isActive }
			});

			if (response.ok) {
				this.setState({ vehicle: response.data?.results?.vehicle, ...response.data?.results?.vehicle });

				notification.success({
					message: Strings.vehicles.vehicle,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async uploadFile() {
		const { file } = this.state;
		const { dispatch } = this.props;

		const data = new FormData();
		data.append("files", file);

		dispatch(setLoader(true));

		try {
			const response = await API.post({
				url: Endpoints.uriVehicles(`import-viaverde`),
				data
			});

			if (response.ok) {
				this.setState({ showImportModal: false, file: null });

				notification.success({
					message: Strings.vehicles.vehicle,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	humanFileSize(bytes: number, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + " B";
		}

		const units = si
			? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
			: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + " " + units[u];
	}

	getType(type: string) {
		if (type === "truck") return Strings.vehicles.truck;
		if (type === "van") return Strings.vehicles.van;
		if (type === "car") return Strings.vehicles.car;
		return type;
	}

	renderModal() {
		const { showImportModal, file } = this.state;
		const { mobile } = this.props;
		let size: any;

		if (file) {
			size = this.humanFileSize(file.size, true);
		}

		return (
			<Modal
				centered={true}
				closable={true}
				onCancel={() => this.setState({ showImportModal: false })}
				visible={showImportModal}
				footer={null}
				bodyStyle={{ padding: 0 }}
				width={mobile ? "100%" : 600}
			>
				<div className="UploadModal">
					<div className="UploadTitle">
						<p>{Strings.vehicles.uploadViaVerde}</p>
					</div>
					<div className="UploadBody">
						<Dropzone
							className="UploadDropzone"
							id="upload_file"
							multiple={false}
							accept="text/csv, .xls, .xlsx"
							onDrop={(files: any) => this.setState({ file: files[0] })}
						>
							{file ? (
								<React.Fragment>
									<p>Name: {file?.name}</p>
									<p>Size: {size}</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Icons.UploadFile />
									<p>{Strings.vehicles.uploadFile}</p>
								</React.Fragment>
							)}
						</Dropzone>
					</div>
					<div className="UploadFooter">
						<button
							className="ImportButton"
							onClick={() => this.uploadFile() }
							disabled={!file}
						>
							{Strings.generic.import}
						</button>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		const { vehicle = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.vehicles}</title>
					<meta name="description" content="Description of Vehicles" />
				</Helmet>
				<Table
					title={{
						icon: "truck",
						title: Strings.sidebar.vehicles
					}}
					headerOptions={[{
						label: Strings.vehicles.viaVerde,
						tooltip: Strings.generic.import,
						icon: "fleet",
						onClick: () => this.setState({ showImportModal: true }),
					}]}
					data={vehicle}
					columns={[
						{
							Header: Strings.vehicles.licensePlate,
							id: "license_plate",
							accessor: (row: any) => row.licensePlate || "-"
						},
						{
							Header: Strings.vehicles.type,
							id: "type",
							accessor: (row: any) => row.type && this.getType(row.type)
						}
					]}
					filterable
					isSinglePage
					fullPage
					paginated={false}
					add={{
						onClick: () => dispatch(push("/vehicles/new"))
					}}
					actions={{
						edit: (original: any) => ({
							onClick: () => dispatch(push(`/vehicles/${original._id}`))
						}),
						remove: (original: any) => ({
							onClick: () => this.removeVehicle(original._id)
						}),
						toggle: (original) => ({
							value: original.isActive,
							onChange: () => this.toggleVehicle(original)
						})
					}}
				/>
				{this.renderModal()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Vehicles);
