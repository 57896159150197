import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { ContentWrapper, Icon } from "components";
import { Col, Dropdown, Input, notification, Row, Select, Switch } from "antd";
import { ChromePicker } from "react-color";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";
import Strings from "utils/strings";

import "./styles.scss";

class AppSettings extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			imageColor: null,
			imageWhite: null,
			hasUnsavedFields: false,
			appImageUse: [
				{ label: "Color", value: "color" },
				{ label: "White", value: "white" }
			]
		};
	}

	async componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(translate(Strings.settings.appSettings)));

		await this.getData();

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							icon: "preferences",
							text: Strings.sidebar.settings,
							route: "/settings"
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							className: "BreadcrumbSaveButton",
							onClick: () => this.handleSubmit(),
							isSave: true
						}
					]
				};
			})
		);
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch, match } = this.props;
		const {
			params: { id }
		} = match || {};

		dispatch(setLoader(true));

		if (id !== "new") {
			let response: any;

			try {
				response = await API.get({
					url: Endpoints.uriSettings()
				});

				if (response.ok) {
					const { settings } = response.data.results || {};

					this.setState({ ...settings, settings });
				} else {
					notification.error({
						message: Strings.serverErrors.title,
						description: response?.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}
			} catch (err) {
				notification.error({
					message: Strings.serverErrors.title,
					description: (err as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		}

		dispatch(setLoader(false));
	}

	async handleSubmit() {
		const {
			imageWhite,
			imageColor,
			primaryColor,
			gradientColor,
			splashImage,
			register,
			socialLogin,
			sentry,
			storeLinks,
			appVersions,
			borderRadius
		} = this.state;
		const { dispatch } = this.props;

		if (!this.isValid()) return;

		const body = new FormData();

		if (imageWhite) {
			body.append("imageWhite", imageWhite?.file || imageWhite);
		}
		if (imageColor) {
			body.append("imageColor", imageColor?.file || imageColor);
		}

		body.append("primaryColor", primaryColor);
		body.append("borderRadius", borderRadius);
		body.append("splashImage", splashImage);
		body.append("gradientColor", JSON.stringify(gradientColor));
		body.append("sentry", sentry);
		body.append("register", register || false);
		body.append("socialLogin", socialLogin || false);
		body.append("storeLinks", JSON.stringify(storeLinks));
		body.append("appVersions", JSON.stringify(appVersions));

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.put({
				url: Endpoints.uriSettings(),
				data: body
			});

			if (response.ok) {
				const { settings } = response.data.results || {};

				this.setState({ ...settings, hasUnsavedFields: false });

				notification.success({
					message: Strings.settings.appSettings,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	isValid() {
		// const {
		//     imageWhite,
		//     imageColor,
		//     primaryColor,
		//     gradientColor,
		//     register,
		//     socialLogin,
		//     sentry,
		//     storeLinks,
		//     appVersions,
		// } = this.state;

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any, prop: any) {
		const file = files[0];

		new Compressor(file, {
			quality: 0.9,
			maxWidth: 1080,
			mimeType: "image/jpeg",
			beforeDraw(context, canvas) {
				context.fillStyle = "rgba(255,255,255,1)";
				context.fillRect(0, 0, canvas.width, canvas.height);
			},
			success: (result) => {
				this.getBase64(result).then((res) => {
					this.setState({
						[prop]: { file: result, preview: res },
						hasUnsavedFields: true
					});
				});
			}
		});
	}

	renderGenericInfo() {
		const { imageWhite, imageColor, gradientColor, primaryColor, splashImage, borderRadius, appImageUse } =
			this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<h2 className="AppSettingsTitle">{Strings.settings.appSettings}</h2>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_image" className="InputLabel">
						{Strings.settings.imageWhite}
					</label>
					<div className="GenericImageDropzone --has-label">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="GenericImageUpload"
							id="appSettings_image"
							multiple={false}
							disabledClassName="DropzoneDisabled"
							onDrop={(files: any) => {
								this.onDrop(files, "imageWhite");
							}}
						>
							{imageWhite ? (
								<div
									className="DropzoneImage"
									style={{
										backgroundImage:
											(imageWhite && `url('${imageWhite?.preview || imageWhite}')`) || "none",
										backgroundSize: "contain"
									}}
								/>
							) : (
								<div className={`GenericImageUploadOverlay${!imageWhite ? " --visible" : ""}`}>
									<Icon name="frame" />
									<span>{Strings.generic.changeImage}</span>
								</div>
							)}
							<div className="FlowImageOptions">
								<button
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();

										this.setState({ imageWhite: null, hasUnsavedFields: true });
									}}
									className="FlowImageOption"
								>
									<Icon name="trash" />
								</button>
							</div>
						</Dropzone>
					</div>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_image" className="InputLabel">
						{Strings.settings.imageColor}
					</label>
					<div className="GenericImageDropzone --has-label">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="GenericImageUpload"
							id="appSettings_image"
							multiple={false}
							disabledClassName="DropzoneDisabled"
							onDrop={(files: any) => {
								this.onDrop(files, "imageColor");
							}}
						>
							{imageColor ? (
								<div
									className="DropzoneImage"
									style={{
										backgroundImage:
											(imageColor && `url('${imageColor?.preview || imageColor}')`) || "none",
										backgroundSize: "contain"
									}}
								/>
							) : (
								<div className={`GenericImageUploadOverlay${!imageColor ? " --visible" : ""}`}>
									<Icon name="frame" />
									<span>{Strings.generic.changeImage}</span>
								</div>
							)}
							<div className="FlowImageOptions">
								<button
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();

										this.setState({ imageColor: null, hasUnsavedFields: true });
									}}
									className="FlowImageOption"
								>
									<Icon name="trash" />
								</button>
							</div>
						</Dropzone>
					</div>
				</Col>
				<Col xs={24} md={12}>
					<Row gutter={[20, 10]}>
						<Col xs={24} md={24}>
							<div className="MultiOptionsLabel">
								<label htmlFor="appSettings_gradient_end" className="InputLabel">
									{Strings.settings.primaryColor}
								</label>
								<div className="LabelOptions">
									<button onClick={() => this.setState({ primaryColor: "" })}>
										<Icon name="eraser" />
									</button>
								</div>
							</div>
							<Dropdown
								overlay={
									<ChromePicker
										color={primaryColor}
										onChangeComplete={(color: any) =>
											this.setState({ primaryColor: color.hex, hasUnsavedFields: true })
										}
									/>
								}
								trigger={["click"]}
							>
								<div className="ColorPickerBlock">
									{Boolean(primaryColor) ? (
										<span style={{ backgroundColor: primaryColor || "#fff" }} />
									) : (
										<p>{Strings.settings.pickColor}</p>
									)}
								</div>
							</Dropdown>
						</Col>
						<Col xs={24} md={12}>
							<div className="MultiOptionsLabel">
								<label htmlFor="appSettings_gradient_end" className="InputLabel">
									{Strings.settings.gradientStart}
								</label>
								<div className="LabelOptions">
									<button
										onClick={() =>
											this.setState((prevState: any) => ({
												gradientColor: { ...prevState.gradientColor, start: "" },
												hasUnsavedFields: true
											}))
										}
									>
										<Icon name="eraser" />
									</button>
								</div>
							</div>
							<Dropdown
								overlay={
									<ChromePicker
										color={gradientColor?.start}
										onChangeComplete={(color: any) =>
											this.setState((prevState: any) => ({
												gradientColor: { ...prevState.gradientColor, start: color.hex },
												hasUnsavedFields: true
											}))
										}
									/>
								}
								trigger={["click"]}
							>
								<div className="ColorPickerBlock">
									{Boolean(gradientColor?.start) ? (
										<span style={{ backgroundColor: gradientColor?.start || "#fff" }} />
									) : (
										<p>{Strings.settings.pickColor}</p>
									)}
								</div>
							</Dropdown>
						</Col>
						<Col xs={24} md={12}>
							<div className="MultiOptionsLabel">
								<label htmlFor="appSettings_gradient_end" className="InputLabel">
									{Strings.settings.gradientEnd}
								</label>
								<div className="LabelOptions">
									<button
										onClick={() =>
											this.setState((prevState: any) => ({
												gradientColor: { ...prevState.gradientColor, end: "" },
												hasUnsavedFields: true
											}))
										}
									>
										<Icon name="eraser" />
									</button>
								</div>
							</div>
							<Dropdown
								overlay={
									<ChromePicker
										color={gradientColor?.end}
										onChangeComplete={(color: any) =>
											this.setState((prevState: any) => ({
												gradientColor: { ...prevState.gradientColor, end: color.hex },
												hasUnsavedFields: true
											}))
										}
									/>
								}
								trigger={["click"]}
							>
								<div className="ColorPickerBlock">
									{Boolean(gradientColor?.end) ? (
										<span style={{ backgroundColor: gradientColor?.end || "#fff" }} />
									) : (
										<p>{Strings.settings.pickColor}</p>
									)}
								</div>
							</Dropdown>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="environment_base_flows" className="InputLabel">
								{Strings.settings.splashImage}
							</label>
							<Select
								id="environment_base_flows"
								style={{ width: "100%" }}
								showSearch
								showArrow
								mode="multiple"
								maxTagCount={4}
								value={splashImage}
								placeholder={Strings.settings.splashImage}
								allowClear
								onChange={(value: any) => {
									this.setState({ splashImage: value, hasUnsavedFields: true });
								}}
							>
								{appImageUse.map((type: any, index: number) => (
									<Select.Option key={`image_use_${index}_${type.value}`} value={type.value}>
										{type.label}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<label htmlFor="appSettings_sentry" className="InputLabel">
								{Strings.settings.borderRadius}
							</label>
							<Input
								id="appSettings_sentry"
								type="number"
								style={{ height: 40 }}
								value={borderRadius || 0}
								placeholder={Strings.settings.borderRadius}
								onChange={(e: any) => {
									const value = e.target.value;
									this.setState({ borderRadius: value, hasUnsavedFields: true });
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	renderSpecifics() {
		const { register, socialLogin, sentry, storeLinks, appVersions } = this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<h2 className="AppSettingsTitle">{Strings.settings.appConfigurations}</h2>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_community_use" className="InputLabel">
						{Strings.settings.register}
					</label>
					<div className={`General_ColorFul_Switch ${register ? "__active" : ""}`}>
						<span>{Strings.settings.register}</span>
						<Switch
							className={`Switch ${register ? "__active" : ""}`}
							checked={register || false}
							size="small"
							onChange={(value: any) =>
								this.setState({
									register: value,
									hasUnsavedFields: true
								})
							}
						/>
					</div>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_subscription" className="InputLabel">
						{Strings.settings.socialLogin}
					</label>
					<div className={`General_ColorFul_Switch ${socialLogin ? "__active" : ""}`}>
						<span>{Strings.settings.socialLogin}</span>
						<Switch
							className={`Switch ${socialLogin ? "__active" : ""}`}
							checked={socialLogin || false}
							size="small"
							onChange={(value: any) =>
								this.setState({
									socialLogin: value,
									hasUnsavedFields: true
								})
							}
						/>
					</div>
				</Col>
				<Col xs={24} md={12}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.sentry}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={sentry || ""}
						placeholder={Strings.settings.sentry}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState({
								sentry: value,
								hasUnsavedFields: true
							});
						}}
					/>
				</Col>
				<Col xs={24} md={12}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.storeLinkAndroid}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={storeLinks?.android || ""}
						placeholder={Strings.settings.storeLinkAndroid}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								storeLinks: { ...prevState.storeLinks, android: value },
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
				<Col xs={24} md={12}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.storeLinkIos}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={storeLinks?.ios || ""}
						placeholder={Strings.settings.storeLinkIos}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								storeLinks: { ...prevState.storeLinks, ios: value },
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.warningVersionsAndroid}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={appVersions?.android?.warningVersion || ""}
						placeholder={Strings.settings.warningVersionsAndroid}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								appVersions: {
									...prevState.appVersions,
									android: { ...prevState?.appVersions?.android, warningVersion: value }
								},
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.criticalVersionsAndroid}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={appVersions?.android?.criticalVersion || ""}
						placeholder={Strings.settings.criticalVersionsAndroid}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								appVersions: {
									...prevState.appVersions,
									android: { ...prevState?.appVersions?.android, criticalVersion: value }
								},
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.warningVersionsIos}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={appVersions?.ios?.warningVersion || ""}
						placeholder={Strings.settings.warningVersionsIos}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								appVersions: {
									...prevState.appVersions,
									ios: { ...prevState?.appVersions?.ios, warningVersion: value }
								},
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
				<Col xs={24} md={6}>
					<label htmlFor="appSettings_sentry" className="InputLabel">
						{Strings.settings.criticalVersionsIos}
					</label>
					<Input
						id="appSettings_sentry"
						style={{ height: 40 }}
						value={appVersions?.ios?.criticalVersion || ""}
						placeholder={Strings.settings.criticalVersionsIos}
						onChange={(e: any) => {
							const value = e.target.value;
							this.setState((prevState: any) => ({
								appVersions: {
									...prevState.appVersions,
									ios: { ...prevState?.appVersions?.ios, criticalVersion: value }
								},
								hasUnsavedFields: true
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div className="ScreenAppSettings">
				<Helmet>
					<title>{Strings.sidebar.settings}</title>
					<meta name="description" content="Edit App Settings" />
				</Helmet>
				<ContentWrapper extraStyle={{ padding: 20 }}>{this.renderGenericInfo()}</ContentWrapper>
				<ContentWrapper extraStyle={{ padding: 20 }}>{this.renderSpecifics()}</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(AppSettings);
