import React, { Component } from "react";
import { connect } from "react-redux";
import Routes, { offlinePages } from "./routes";
import { Sidebar, Header, ErrorBoundary } from "screens";
import { Content, RouteContent, Loader, StaffModal, WorksheetButton } from "components";
import { setBreadcrumb, setMobile } from "store/actions";
import { ConfigProvider } from "antd";
import pt from "antd/es/locale/pt_PT";
import en from "antd/es/locale/en_GB";

import "styles/styles.scss";
import "styles/theme.less";
import strings from "utils/strings";

export class App extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			sidebarOpen: window.innerWidth >= 992,
			sidebarHidden: false
		};

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener("resize", this.handleResize);
	}

	UNSAFE_componentWillMount() {
		strings.setLanguage(this.props.language || "pt");

		this.handleResize();
	}

	componentDidUpdate(nextProps: any) {
		const { dispatch, router } = this.props;

		if (
			nextProps?.router?.location?.pathname !== router?.location?.pathname &&
			(!router?.location?.pathname?.split("/").some((path: string) => path === "new") ||
				router?.location?.pathname?.split("/").length !== nextProps?.location?.pathname?.split("/").length)
		) {
			dispatch(setBreadcrumb(null));
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize() {
		const { dispatch } = this.props;
		const isMobile = window.innerWidth < 992;

		this.setState((state: any) => ({ isMobile, sidebarOpen: !isMobile }));
		dispatch(setMobile(isMobile));
	}

	openSidebar() {
		this.setState({ sidebarOpen: true });
	}

	closeSidebar() {
		if (document.body.clientWidth < 992) {
			this.setState({ sidebarHidden: true, sidebarOpen: false });
		} else {
			this.setState({ sidebarOpen: false });
		}
	}

	get hideNavBar() {
		const { router } = this.props;
		const { location } = router;
		const path = location.pathname.split("/")[1];

		return offlinePages.indexOf(location.pathname) !== -1 || offlinePages.indexOf(`/${path}`) !== -1;
	}

	get hideWorksheetButtons() {
		const { router, user } = this.props;
		const { location } = router;
		const path = location.pathname.split("/")[1];
		const admins = user?.role === 'admin' || user?.role === 'sysadmin';

		return (
			offlinePages.indexOf(location.pathname) !== -1 ||
			offlinePages.indexOf(`/${path}`) !== -1 ||
			path === "worksheets",
			!admins
		);
	}

	render() {
		const { language } = this.props;

		let locale = pt;
		if (language === "en") {
			locale = en;
		}

		return (
			<div className="App">
				<ConfigProvider locale={locale}>
					{!this.hideNavBar && (
						<Sidebar
							open={this.state.sidebarOpen}
							onMobile={(status: any) => status && this.closeSidebar()}
							openSidebar={() => this.openSidebar()}
							closeSidebar={() => this.closeSidebar()}
						/>
					)}
					<Content>
						{!this.hideNavBar && (
							<Header
								sidebarOpen={this.state.sidebarOpen}
								onToggleSidebar={() => {
									this.setState((state: any) => ({
										sidebarHidden: !state.sidebarHidden,
										sidebarOpen: !state.sidebarOpen
									}));
								}}
							/>
						)}
						<RouteContent style={this.hideNavBar ? { padding: 0 } : null}>
							<ErrorBoundary>
								<Routes />
							</ErrorBoundary>
						</RouteContent>
					</Content>
					<StaffModal />
					<Loader />
				</ConfigProvider>
				{!this.hideWorksheetButtons && <WorksheetButton />}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	loader: state.loader,
	breadcrumb: state.breadcrumb,
	language: state.language,
	user: state.user,
});

export default connect(mapStateToProps)(App);
