import React, { Component } from "react";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Table } from "components";
import { setBreadcrumb, setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { notification } from "antd";

class Customers extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			customers: []
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(setBreadcrumb(null));

		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriCustomers()
			});

			if (response.ok) {
				this.setState({ customers: response.data?.results?.customer || [] });
			}
		} catch (err) {
			console.log("API Error", err);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeCustomer(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriCustomers(id)
			});

			if (response.ok) {
				this.setState({ customers: response.data?.results?.customer, ...response.data?.results?.customer });

				notification.success({
					message: Strings.customers.customer,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	toggleCustomer = async (customer: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const body = {
				isActive: !customer.isActive
			};

			const response = await API.patch({
				url: Endpoints.uriCustomers(`${customer._id}/status`),
				data: body
			});

			if (response?.ok) {
				const { customer = [] } = response.data.results;

				this.setState({ customers: customer });

				notification.success({
					message: Strings.sidebar.clients,
					description:
						response.data.message || Strings.sidebar.clients,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}
		dispatch(setLoader(false));
	};

	render() {
		const { customers = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.clients}</title>
					<meta name="description" content="Description of Customers" />
				</Helmet>
				<Table
					title={{
						icon: "user-group",
						title: Strings.sidebar.clients
					}}
					data={customers}
					columns={[
						{
							Header: Strings.fields.logo,
							id: "photo",
							accessor: (row: any) => row.image,
							type: "image",
							maxWidth: 65
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name
						},
						{
							Header: Strings.fields.email,
							id: "email",
							accessor: (row: any) => row.email
						},
						{
							Header: Strings.fields.phone,
							id: "phone",
							accessor: (user: any) => user.phone || "-"
						},
						{
							Header: Strings.fields.city,
							id: "city",
							accessor: (user: any) => user.city
						}
					]}
					filterable
					isSinglePage
					fullPage
					paginated={false}
					add={{
						onClick: () => dispatch(push("/customers/new"))
					}}
					actions={{
						edit: (original: any) => ({
							onClick: () => dispatch(push(`/customers/${original._id}`))
						}),
						remove: (original: any) => ({
							onClick: () => this.removeCustomer(original._id)
						}),
						toggle: (original: any) => ({
							value: original.isActive,
							onChange: () => this.toggleCustomer(original)
						})
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(Customers);
