import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "components";
import {
	Dashboard,
	Login,
	RecoverPassword,
	Staff,
	StaffDetail,
	Logs,
	Settings,
	AppSettings,
	AcceptInvite,
	Pages,
	EmailTemplates,
	PageDetail,
	EmailDetail,
	Error500,
	Customers,
	CustomerDetail,
	Vehicles,
	VehicleDetail,
	ExpensesTypes,
	Worksheets,
	WorksheetDetail,
	DailyView
} from "screens";

export const offlinePages = ["/login", "/register", "/accept-invite", "/recover-password", "/confirm"];

export class Routes extends React.Component<any, any> {
	shouldComponentUpdate(nextProps: any) {
		const { user, token } = this.props;
		const isLoggedIn = Boolean(user && token);
		const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

		return isLoggedIn !== willBeLoggedIn;
	}

	componentDidUpdate() {
		const elem = document.getElementById("app_content");

		if (elem) {
			elem.scrollTop = 0;
		}
	}

	render() {
		const { token, router, user } = this.props;
		const { location } = router;

		if (token && user) {
			location.pathname = "/dashboard";
		} else {
			location.pathname = "/login";
		}

		const path = location.pathname.split("/")[1];
		const isAdmin = user?.role === "sysadmin" || user?.role === "admin";

		if (offlinePages.indexOf(location.pathname) !== -1 || offlinePages.indexOf(`/${path}`) !== -1) {
			return (
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/recover-password" component={RecoverPassword} />
					<Route exact path="/recover-password/:id/:code" component={RecoverPassword} />
					<Route exact path="/accept-invite/:id/:code" component={AcceptInvite} />
					<Route exact path="/confirm/:email/:code" component={() => <h1>Confirm Account</h1>} />
					<Route exact path="/500" component={Error500} />
					<Redirect to="/login" />
				</Switch>
			);
		}

		return (
			<React.Fragment>
				<Switch>
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					{(isAdmin && <PrivateRoute exact path="/staff" component={Staff} />) || null}
					{(isAdmin && <PrivateRoute exact path="/staff/:id" component={StaffDetail} />) || null}
					{(isAdmin && <PrivateRoute exact path="/customers" component={Customers} />) || null}
					{(isAdmin && <PrivateRoute exact path="/customers/:id" component={CustomerDetail} />) || null}
					{(isAdmin && <PrivateRoute exact path="/vehicles" component={Vehicles} />) || null}
					{(isAdmin && <PrivateRoute exact path="/vehicles/:id" component={VehicleDetail} />) || null}
					{(isAdmin && <PrivateRoute exact path="/worksheets" component={Worksheets} />) || null}
					{(isAdmin && <PrivateRoute exact path="/daily-view" component={DailyView} />) || null}
					<PrivateRoute exact path="/worksheets/:id" component={WorksheetDetail} />
					<PrivateRoute exact path="/settings" component={Settings} />
					<PrivateRoute exact path="/settings/app-settings" component={AppSettings} />
					<PrivateRoute exact path="/settings/pages" component={Pages} />
					<PrivateRoute exact path="/settings/pages/:id" component={PageDetail} />
					<PrivateRoute exact path="/settings/email-templates" component={EmailTemplates} />
					<PrivateRoute exact path="/settings/email-templates/:id" component={EmailDetail} />
					<PrivateRoute exact path="/settings/cost-types" component={ExpensesTypes} />
					<PrivateRoute exact path="/logs" component={Logs} />
					<PrivateRoute exact path="/500" component={Error500} />
					<Redirect to="/dashboard" />
				</Switch>
				{/* Mitigate mobile url bars */}
				{window.innerWidth < 768 && <div style={{ height: 160 }} />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	user: state.user,
	token: state.token
});

export default connect(mapStateToProps)(Routes);
