import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Icon, Table } from "components";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { Col, Drawer, Input, notification, Row, Select, Switch } from "antd";
import { LANGUAGES, translate } from "utils/utils";

class ExpensesTypes extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			type: [],
			language: "pt"
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.expensesTypes,
							icon: "expenses"
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriExpensesTypes()
			});

			if (response.ok) {
				this.setState({ type: response.data?.results?.costType || [] });
			}
		} catch (err) {
			console.log("API Error", err);
		}

		dispatch(setLoader(false));
	}

	async submitType() {
		const { tempType } = this.state;
		const { dispatch } = this.props;

		if (!translate(tempType?.description)) {
			return notification.warn({
				message: Strings.settings.expensesTypes,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		try {
			const request = tempType._id ? API.put : API.post;
			
			const response = await request({
				url: Endpoints.uriExpensesTypes(tempType._id || ""),
				data: { ...tempType }
			});
			delete tempType._id;

			if (response.ok && response.data.results?.costType) {
				this.setState({
					showDrawer: false,
					tempType: null,
					type: response.data.results.costType,
					...response.data.results.costType
				});

				notification.success({
					message: Strings.settings.expensesTypes,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeType(id: string) {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriExpensesTypes(id)
			});

			if (response.ok) {
				this.setState({ type: response.data?.results?.costType });

				notification.success({
					message: Strings.settings.expensesTypes,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	toggleType = async (type: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const body = {
				isActive: !type.isActive
			};

			const response = await API.patch({
				url: Endpoints.uriExpensesTypes(`${type._id}/status`),
				data: body
			});

			if (response?.ok) {
				const { costType = [] } = response.data.results;

				this.setState({ type: costType });

				notification.success({
					message: Strings.settings.expensesTypes,
					description:
						response.data.message || Strings.settings.expensesTypes,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}
		dispatch(setLoader(false));
	};

	renderDrawer() {
		const { showDrawer } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="expenses" />
						<p>{Strings.settings.expensesTypes}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.submitType()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showDrawer: false, tempEntry: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempEntry: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempType, language } = this.state;

		return (
			<React.Fragment>
				<div className="DrawerLanguageContainer">
					<Select
						className="DrawerLanguageDropdown"
						optionFilterProp="children"
						onChange={(language) => this.setState({ language })}
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={language}
					>
						{LANGUAGES.map((lang: any, index: number) => {
							return (
								<Select.Option key={`select_option_${lang}_${index}`} value={lang.value}>
									{lang.label}
								</Select.Option>
							);
						})}
					</Select>
				</div>
				<Row gutter={[0, 10]}>
					<Col xs={24}>
						<label htmlFor="expenses_type" className="InputLabel --label-required">
							{Strings.fields.description}
						</label>
						<Input
							id="expenses_type"
							value={tempType?.description?.[language] || ""}
							placeholder={Strings.fields.description}
							onChange={(event: any) => {
								const value = event.target.value;
								this.setState((state: any) => ({
									tempType: {
										...state.tempType,
										description: { ...state.tempType?.description, [language]: value }
									}
								}));
							}}
						/>
					</Col>
					<Col xs={24}>
                        <div
                            className={`General_ColorFul_Switch ${
                                tempType?.isToll ? "__active" : ""
                            }`}
                        >
                            <span>{Strings.worksheets.toll}</span>
                            <Switch
                                className={`Switch ${
                                    tempType?.isToll ? "__active" : ""
                                }`}
                                checked={(tempType?.isToll) || false}
                                size="small"
                                onChange={() =>
                                    this.setState((prevState: any) => ({
                                        tempType: {
                                            ...tempType,
                                            isToll: !prevState?.tempType?.isToll,
                                        },
                                        hasUnsavedFields: true,
                                    }))
                                }
                            />
                        </div>
                    </Col>
				</Row>
			</React.Fragment>
		);
	}

	render() {
		const { type = [] } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.expensesTypes}</title>
					<meta name="description" content="Description of expenses types" />
				</Helmet>
				<Table
					title={{
						icon: "expenses",
						title: Strings.settings.expensesTypes
					}}
					data={type}
					columns={[
						{
							Header: Strings.fields.description,
							id: "description",
							accessor: (row: any) => translate(row.description) || "-"
						}
					]}
					filterable
					isSinglePage
					fullPage
					paginated={false}
					add={{
						onClick: () => this.setState({ showDrawer: true, tempType: {} })
					}}
					actions={{
						edit: (original: any) => ({
							onClick: () => this.setState({ showDrawer: true, tempType: { ...original } })
						}),
						remove: (original: any) => ({
							onClick: () => this.removeType(original._id)
						}),
						toggle: (original: any) => ({
							value: original.isActive,
							onChange: () => this.toggleType(original)
						})
					}}
				/>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(ExpensesTypes);
