// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-image:linear-gradient(180deg, #bbbbbb 1.04%, #7c7c7c 100%);background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto}.LoginWrapper .LoginLogo{margin-top:100px;display:flex;align-items:center;width:100%;height:150px;margin-left:10%;font-size:35px}.LoginWrapper .LoginContent{display:flex;flex-direction:column;justify-content:center;height:calc(100% - 150px);width:100%;margin-left:10%}.LoginWrapper .LoginContent .LoginWelcome{font-size:20px;font-weight:700;color:#fff}.LoginWrapper .LoginContent .LoginForgotPassword{font-size:12px;margin-top:5px;display:flex;justify-content:flex-end;color:#fff}.LoginWrapper .LoginContent .isLink{cursor:pointer}.LoginWrapper .LoginContent .isLink:hover{color:#85ba17}.LoginWrapper .LoginContent .LoginButton{margin-top:30px;width:100%;background-color:#85ba17}.LoginWrapper .LoginContent form{margin-top:20px;width:350px}.LoginWrapper .LoginContent form em{color:#85ba17;font-weight:700}.LoginWrapper.__isMobile{padding:0 20px}.LoginWrapper.__isMobile .LoginLogo{margin-left:0}.LoginWrapper.__isMobile .LoginLogo,.LoginWrapper.__isMobile .LoginContent{margin-left:0}.LoginWrapper.__isMobile form{width:100%}.authyButton{display:flex;justify-content:center;align-items:center;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
