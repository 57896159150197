import React, { Component } from "react";
import { ContentWrapper, Icon, Table } from "components";
import { Row, Col, Input, notification, Tabs, Drawer, Select } from "antd";
import Dropzone from "react-dropzone";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { push } from "connected-react-router";
import { DateTime } from "luxon";
import "./styles.scss";

class VehicleDetail extends Component<any, any> {
	phoneValidationInput: any;

	constructor(props: any) {
		super(props);

		this.state = {
			vehicle: null,
			hasUnsavedFields: false,
			isNew: props.match?.params?.id === "new",
			tab: "1"
		};
	}

	componentDidMount(): void {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.vehicles,
							route: "/vehicles",
							icon: "truck"
						},
						{
							text: this.state.isNew
								? Strings.vehicles.new
								: this.state.licensePlate || Strings.generic.loading
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitVehicle(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate(): void {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
	}

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match } = this.props;

		if (isNew) {
			return dispatch(setTitle(Strings.vehicles.new));
		}

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriVehicles(match?.params?.id)
			});

			if (response.ok) {
				dispatch(setTitle(response.data?.results?.vehicle?.licensePlate || Strings.generic.loading));
				this.setState({ vehicle: response.data?.results?.vehicle, ...response.data?.results?.vehicle });
			}
		} catch (err) {
			console.log("API Error", err);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitVehicle() {
		const { licensePlate, type, isNew } = this.state;
		const { dispatch, match } = this.props;

		if (!licensePlate?.trim() || !type) {
			return notification.warn({
				message: Strings.vehicles.vehicle,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		try {
			const request = !isNew ? API.put : API.post;
			console.log("request", request);
			const response = await request({
				url: Endpoints.uriVehicles((!isNew && match?.params?.id) || ""),
				data: {
					licensePlate,
					type
				}
			});

			if (response.ok && response.data.results?.vehicle) {
				if (isNew) {
					return dispatch(push("/vehicles"));
				}

				this.setState({
					hasUnsavedFields: false,
					vehicle: response.data.results.vehicle,
					showDrawer: false,
					tempEntry: null,
					...response.data.results.vehicle
				});

				dispatch(setTitle(response.data.results?.vehicle?.licensePlate));

				notification.success({
					message: Strings.vehicles.vehicle,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitDocument() {
		const { tempEntry } = this.state;
		const { dispatch, match } = this.props;

		if (!tempEntry?.description?.trim() || !tempEntry?.file) {
			return notification.warn({
				message: Strings.staff.documents,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		const body = new FormData();
		body.append("description", tempEntry.description);
		body.append("document", tempEntry.file);

		try {
			const request = tempEntry._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriVehicles(`${match?.params?.id}/document/${tempEntry._id || ""}`),
				data: body
			});

			if (response.ok && response.data.results?.vehicle) {
				this.setState({
					showDrawer: false,
					tempEntry: null,
					vehicle: response.data.results.vehicle,
					...response.data.results.vehicle
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeDocument(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriVehicles(`${match?.params?.id}/document/${id}`)
			});

			if (response.ok) {
				this.setState({ vehicle: response.data?.results?.vehicle, ...response.data?.results?.vehicle });

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	onDropFile = async (files: any) => {
		try {
			this.setState((state: any) => ({
				tempEntry: {
					...state.tempEntry,
					file: files[files.length - 1]
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	getType(type: string) {
		if (type === "truck") return Strings.vehicles.truck;
		if (type === "van") return Strings.vehicles.van;
		if (type === "car") return Strings.vehicles.car;
		return type;
	}

	getStatus(status: string) {
		if (status === "pending") return Strings.worksheets.pending;
		if (status === "started") return Strings.worksheets.started;
		if (status === "finished") return Strings.worksheets.finished;
		return status;
	}

	renderWorksheets() {
		const { worksheets = [] } = this.state;

		return (
			<Table
				title={{
					icon: "duplicate",
					title: Strings.worksheets.worksheets
				}}
				data={worksheets}
				columns={[
					{
						Header: Strings.fields.date,
						id: "date",
						accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy"),
						minWidth: 50
					},
					{
						Header: Strings.fields.number,
						id: "number",
						minWidth: 50,
						accessor: (row: any) => row.number
					},
					{
						Header: Strings.staff.driver,
						id: "staff",
						minWidth: 150,
						accessor: (row: any) => row.staff
					},
					{
						Header: Strings.customers.customer,
						id: "name",
						minWidth: 150,
						accessor: (row: any) => row.customer
					},
					{
						Header: Strings.worksheets.status,
						id: "status",
						minWidth: 70,
						accessor: (row: any) => this.getStatus(row.status) || "-"
					},
					{
						Header: Strings.worksheets.exitHour,
						id: "exitHour",
						minWidth: 70,
						accessor: (row: any) =>
							(row.exitHour && DateTime.fromISO(row.exitHour).toFormat("HH:mm")) || "-"
					},
					{
						Header: Strings.worksheets.returnHour,
						id: "returnHour",
						minWidth: 70,
						accessor: (row: any) =>
							(row.returnHour && DateTime.fromISO(row.returnHour).toFormat("HH:mm")) || "-"
					},
					{
						Header: Strings.worksheets.exitKm,
						id: "exitKm",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => row.exitKm || "-"
					},
					{
						Header: Strings.worksheets.returnKm,
						id: "returnKm",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => row.returnKm || "-"
					}
				]}
				filterable
				paginated={true}
			/>
		);
	}

	renderRefuels() {
		const { refuels = [] } = this.state;

		return (
			<Table
				title={{
					icon: "expenses",
					title: Strings.worksheets.refuels
				}}
				data={refuels}
				columns={[
					{
						Header: Strings.fields.date,
						id: "date",
						accessor: (row: any) => row.date && DateTime.fromISO(row.date).toFormat("dd/MM/yyyy"),
						minWidth: 50
					},
					{
						Header: Strings.fields.number,
						id: "number",
						minWidth: 50,
						accessor: (row: any) => row.number
					},
					{
						Header: Strings.staff.driver,
						id: "staff",
						minWidth: 150,
						accessor: (row: any) => row.staff
					},
					{
						Header: Strings.worksheets.notes,
						id: "name",
						minWidth: 150,
						accessor: (row: any) => row.notes
					},
					{
						Header: Strings.vehicles.kms,
						id: "km",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => row.km
					},
					{
						Header: Strings.worksheets.amount,
						id: "value",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => (row.value && `${row.value} €`) || "-"
					},
					{
						Header: Strings.worksheets.liters,
						id: "liters",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => row.liters || 0
					},
					{
						Header: Strings.worksheets.amountAdBlue,
						id: "valueAdBlue",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => (row.valueAdBlue && `${row.valueAdBlue} €`) || "-"
					},
					{
						Header: Strings.worksheets.litersAdBlue,
						id: "litersAdBlue",
						minWidth: 70,
						align: "right",
						accessor: (row: any) => row.litersAdBlue || 0
					}
				]}
				filterable
				paginated={true}
			/>
		);
	}

	renderDocuments() {
		const { documents = [] } = this.state;

		return (
			<Table
				title={{
					icon: "working-briefcase",
					title: Strings.staff.documents
				}}
				data={documents}
				columns={[
					{
						Header: Strings.fields.name,
						id: "description",
						accessor: (row: any) => row.description
					}
				]}
				filterable
				paginated={false}
				add={{
					onClick: () => this.setState({ tempEntry: {}, showDrawer: true, drawerType: "documents" })
				}}
				actions={{
					edit: (original: any) => ({
						onClick: () => this.setState({ tempEntry: { ...original }, showDrawer: true })
					}),
					view: (original: any) => ({
						onClick: () => window.open(original.file)
					}),
					remove: (original: any) => ({
						onClick: () => this.removeDocument(original._id)
					})
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name={"working-briefcase"} />
						<p>{Strings.staff.documents}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => {
								this.submitDocument();
							}}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ openSidebar: false, tempEntry: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempEntry: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempEntry } = this.state;

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="document_descritpion" className="InputLabel --label-required">
						{Strings.fields.description}
					</label>
					<Input
						id="document_descritpion"
						value={tempEntry?.description || ""}
						placeholder={Strings.fields.description}
						onChange={(event: any) => {
							const value = event.target.value;
							this.setState((state: any) => ({
								tempEntry: { ...state.tempEntry, description: value }
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="document_file" className="InputLabel --label-required">
						{Strings.fields.file}
					</label>
					<Dropzone
						accept=".pdf, .png, .jpg, .jpeg"
						multiple={false}
						style={{ cursor: "pointer" }}
						className="ant-input"
						onDrop={(files) => this.onDropFile(files)}
					>
						<div className="FileUpload">
							<Icon
								name="document"
								style={{ marginRight: "10px", color: tempEntry?.file ? "#333" : "#bfbfbf" }}
							/>
							<span
								style={{
									color: tempEntry?.file ? "#333" : "#bfbfbf",
									fontSize: 12,
									wordBreak: "break-all"
								}}
							>
								{tempEntry?.file
									? typeof tempEntry.file === "string"
										? tempEntry.file
										: tempEntry.file.name
									: Strings.fields.clickToUpload}
							</span>
						</div>
					</Dropzone>
				</Col>
			</Row>
		);
	}

	render(): JSX.Element {
		const { isNew, licensePlate, type, tab } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>
						{Strings.vehicles.vehicle} -{" "}
						{isNew ? Strings.vehicles.new : licensePlate || Strings.generic.loading}
					</title>
					<meta name="description" content="Description of Vehicle" />
				</Helmet>
				<div className="VehicleScreen">
					<ContentWrapper extraClass="VehicleContainer">
						<Row gutter={[20, 10]}>
							<Col xs={12}>
								<label htmlFor="license_plate" className="InputLabel --label-required">
									{Strings.vehicles.licensePlate}
								</label>
								<Input
									id="license_plate"
									placeholder={Strings.vehicles.licensePlate}
									value={licensePlate || ""}
									onChange={(event: any) => {
										this.setState({ licensePlate: event.target.value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="type" className="InputLabel --label-required">
									{Strings.vehicles.type}
								</label>
								<Select
									id="type"
									className="tagsSelector"
									style={{ width: "100%" }}
									placeholder={Strings.vehicles.type}
									showSearch
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={type || null}
									onChange={(value: any) => {
										this.setState({ type: value, hasUnsavedFields: true });
									}}
								>
									<Select.Option value="truck">{Strings.vehicles.truck}</Select.Option>
									<Select.Option value="van">{Strings.vehicles.van}</Select.Option>
									<Select.Option value="car">{Strings.vehicles.car}</Select.Option>
								</Select>
							</Col>
						</Row>
					</ContentWrapper>
					{!isNew && (
						<ContentWrapper extraStyle={{ padding: 20 }}>
							<Tabs onChange={(key: string) => this.setState({ tab: key })} defaultActiveKey="1">
								<Tabs.TabPane tab={Strings.worksheets.worksheets} key="1" />
								<Tabs.TabPane tab={Strings.worksheets.refuels} key="2" />
								<Tabs.TabPane tab={Strings.staff.documents} key="3" />
							</Tabs>
							{tab === "1" && this.renderWorksheets()}
							{tab === "2" && this.renderRefuels()}
							{tab === "3" && this.renderDocuments()}
						</ContentWrapper>
					)}
				</div>
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(VehicleDetail);
