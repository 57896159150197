// TODO: Need better solution for this
const API_URL = process.env.API_URL;

export default {
	uriLogin(id = "") {
		console.log("url", API_URL);
		return `${API_URL}/auth/login/${id}`;
	},

	uriAnalytics() {
		return `${API_URL}/statistics/dashboard`;
	},

	uriLogs(id = "") {
		return `${API_URL}/statistics/logs/${id}`;
	},

	uriLogout() {
		return `${API_URL}/auth/logout`;
	},

	uriStaff(id = "") {
		return `${API_URL}/staff/${id}`;
	},

	uriRecoverPassword(id = "") {
		return `${API_URL}/staff/recover-password/${id}`;
	},

	uriStaffPassword() {
		return `${API_URL}/staff/password`;
	},

	uriStaffEmail() {
		return `${API_URL}/staff/email`;
	},

	uriPages(id = "") {
		return `${API_URL}/pages/${id}`;
	},

	uriEmailTemplate(id = "") {
		return `${API_URL}/email-templates/${id}`;
	},

	uriUsers(id = "") {
		return `${API_URL}/users/${id}`;
	},

	uriNotifications(id = "") {
		return `${API_URL}/notifications/${id}`;
	},

	uriCountries(id = "") {
		return `${API_URL}/countries/${id}`;
	},

	uriSettings() {
		return `${API_URL}/settings/`;
	},

	uriCustomers(id = "") {
		return `${API_URL}/customers/${id}`;
	},

	uriVehicles(id = "") {
		return `${API_URL}/vehicles/${id}`;
	},

	uriExpensesTypes(id = "") {
		return `${API_URL}/cost-types/${id}`;
	},

	uriWorksheets(id = "") {
		return `${API_URL}/worksheets/${id}`;
	}
};
