// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/backgrounds/login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ForgotPasswordWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-image:linear-gradient(#ffffff90, #ffffff),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;overflow:hidden auto}.ForgotPasswordWrapper .ForgotPasswordLogo{display:flex;align-items:center;width:100%;height:150px;margin-left:10%;font-size:35px}.ForgotPasswordWrapper .ForgotPasswordContent{display:flex;flex-direction:column;justify-content:center;height:calc(100% - 150px);width:100%;margin-left:10%}.ForgotPasswordWrapper .ForgotPasswordContent .ForgotPasswordWelcome{font-size:20px;font-weight:700;color:#333}.ForgotPasswordWrapper .ForgotPasswordContent .ForgotPasswordLogin{font-size:12px;margin-top:5px;display:flex;justify-content:flex-end}.ForgotPasswordWrapper .ForgotPasswordContent .isLink{cursor:pointer}.ForgotPasswordWrapper .ForgotPasswordContent .isLink:hover{color:#85ba17}.ForgotPasswordWrapper .ForgotPasswordContent .ForgotPasswordButton{margin-top:30px;width:100%}.ForgotPasswordWrapper .ForgotPasswordContent form{margin-top:20px;width:350px}.ForgotPasswordWrapper .ForgotPasswordContent form em{color:#85ba17;font-weight:700}.ForgotPasswordWrapper.__isMobile{padding:0 20px}.ForgotPasswordWrapper.__isMobile .ForgotPasswordLogo{margin-left:0}.ForgotPasswordWrapper.__isMobile .ForgotPasswordLogo,.ForgotPasswordWrapper.__isMobile .ForgotPasswordContent{margin-left:0}.ForgotPasswordWrapper.__isMobile form{width:100%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#85ba17",
	"lightPrimary": "#32cd32",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
