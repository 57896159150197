import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Props } from "./types";
import { Helmet } from "react-helmet";
import Compressor from "compressorjs";
import { ContentWrapper, Icon, PhoneInput, Table } from "components";
import { Col, Input, notification, Row, Select, Drawer } from "antd";
import Dropzone from "react-dropzone";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import "./styles.scss";

class StaffDetail extends React.Component<Props, any> {
	phoneValidationInput: any;
	constructor(props: Props) {
		super(props);

		this.state = {
			staff: null,
			name: null,
			email: null,
			phone: null,
			photo: null,
			hasUnsavedFields: false
		};

		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount() {
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.staff,
							route: "/staff",
							icon: "working-briefcase"
						},
						{
							text: translate(this.state.staff?.name || "")
						}
					],
					actions: [
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () => this.submitStaff(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
							isSave: true
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(updateCrumb());
		dispatch(setTitle(`${Strings.sidebar.staff} - `));
	}

	async getData() {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriStaff(match?.params?.id)
			});

			if (response.ok) {
				const { staff } = response.data.results || {};
				this.setState({ staff, ...staff });
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.staff,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});

			console.log("Error", err as string);
		}

		dispatch(setLoader(false));
	}

	async submitStaff() {
		const { role, name, photo } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validStaff()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const body = new FormData();

			body.append("name", name);
			body.append("role", role);

			if (photo && Object.keys(photo).length > 0) {
				if (photo.file) {
					body.append("photo", photo.file);
				} else {
					body.append("photo", photo);
				}
			}

			response = await API.put({
				url: Endpoints.uriStaff(match?.params?.id),
				data: body
			});

			if (response.ok) {
				const { staff } = response.data.results;
				this.setState({
					staff,
					...staff,
					hasUnsavedFields: false
				});

				notification.success({
					message: Strings.sidebar.staff,
					description: Strings.staff.editedSuccessfully,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.sidebar.staff,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.staff,
				description: response?.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async submitDocument() {
		const { tempDocument } = this.state;
		const { dispatch, match } = this.props;

		if (!tempDocument?.description?.trim() || !tempDocument?.file) {
			return notification.warn({
				message: Strings.staff.documents,
				description: Strings.errors.invalidFields,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(true));

		const body = new FormData();
		body.append("description", tempDocument.description);
		body.append("document", tempDocument.file);

		try {
			const request = tempDocument._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriStaff(`${match?.params?.id}/document/${tempDocument._id || ""}`),
				data: body
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					openSidebar: false,
					tempDocument: null,
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async removeDocument(id: string) {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriStaff(`${match?.params?.id}/document/${id}`)
			});

			if (response.ok && response.data.results?.staff) {
				this.setState({
					...response.data.results.staff
				});

				notification.success({
					message: Strings.staff.documents,
					description: response.data?.message || "",
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.serverErrors.title,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validStaff() {
		const { role, name } = this.state;

		if (!name) {
			notification.warn({
				message: Strings.sidebar.staff,
				description: Strings.staff.user,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		if (!role) {
			notification.warn({
				message: Strings.sidebar.staff,
				description: Strings.staff.user,
				placement: "bottomRight",
				duration: 5
			});

			return false;
		}

		return true;
	}

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: any) {
		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							photo: { file: result, preview: res },
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	onDropFile = async (files: any) => {
		try {
			this.setState((state: any) => ({
				tempDocument: {
					...state.tempDocument,
					file: files[files.length - 1]
				}
			}));
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	};

	renderSidebarContent() {
		const { tempDocument } = this.state;

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="document_descritpion" className="InputLabel --label-required">
						{Strings.fields.description}
					</label>
					<Input
						id="document_descritpion"
						value={tempDocument?.description || ""}
						placeholder={Strings.fields.description}
						onChange={(event: any) => {
							const value = event.target.value;
							this.setState((state: any) => ({
								tempDocument: { ...state.tempDocument, description: value }
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="document_file" className="InputLabel --label-required">
						{Strings.fields.file}
					</label>
					<Dropzone
						accept=".pdf, .png, .jpg, .jpeg"
						multiple={false}
						style={{ cursor: "pointer" }}
						className="ant-input"
						onDrop={(files) => this.onDropFile(files)}
					>
						<div className="FileUpload">
							<Icon
								name="document"
								style={{ marginRight: "10px", color: tempDocument?.file ? "#333" : "#bfbfbf" }}
							/>
							<span
								style={{
									color: tempDocument?.file ? "#333" : "#bfbfbf",
									fontSize: 12,
									wordBreak: "break-all"
								}}
							>
								{tempDocument?.file
									? typeof tempDocument.file === "string"
										? tempDocument.file
										: tempDocument.file.name
									: Strings.fields.clickToUpload}
							</span>
						</div>
					</Dropzone>
				</Col>
			</Row>
		);
	}

	renderDrawer() {
		const { openSidebar } = this.state;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="working-briefcase" />
						<p>{Strings.staff.documents}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.submitDocument()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ openSidebar: false, tempDocument: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={400}
				onClose={() => this.setState({ tempDocument: null, openSidebar: false })}
				visible={openSidebar}
			>
				{this.renderSidebarContent()}
			</Drawer>
		);
	}

	render() {
		const { name, email, photo, phone, role, documents } = this.state;
		const cnt = "PT";

		return (
			<div className="UserDetailScreen">
				<Helmet>
					<title>{Strings.users.header}</title>
					<meta name="description" content="Edit your user\'s information" />
				</Helmet>
				<ContentWrapper extraClass="UserContainer">
					<div className="UserAvatarContainer">
						<Dropzone
							accept="image/jpg, image/jpeg, image/png"
							className="UserAvatarInnerContainer"
							onDrop={this.onDrop}
						>
							<div className="AvatarWrapper">
								{photo ? (
									<img src={photo?.preview || photo} alt="customer avatar" />
								) : (
									<div className="UserAvatarPlaceholder">
										<Icon name="frame" />
										<p>{Strings.generic.userImage}</p>
									</div>
								)}
							</div>
						</Dropzone>
					</div>
					<div className="UserInformationWrapper">
						<Row gutter={[20, 20]}>
							<Col xs={12}>
								<label htmlFor="name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="name"
									placeholder={Strings.fields.name}
									value={name || ""}
									onChange={(event: any) => {
										const value = event.target.value;
										this.setState({ name: value, hasUnsavedFields: true });
									}}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="email" className="InputLabel --label-required">
									{Strings.fields.email}
								</label>
								<Input
									id="email"
									type="email"
									disabled={true}
									placeholder={Strings.fields.email}
									value={email || ""}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="phone" className="InputLabel">
									{Strings.fields.phone}
								</label>
								{Boolean(cnt) && (
									<PhoneInput
										ref={(ref: any) => {
											this.phoneValidationInput = ref;
										}}
										defaultCountry={cnt && cnt.toLowerCase()}
										value={phone || ""}
										inputClass="input-phone"
										onChange={(event: any) => {
											const value = event.target.value;
											this.setState({ phone: value, hasUnsavedFields: true });
										}}
									/>
								)}
							</Col>
							<Col xs={12}>
								<label htmlFor="role" className="InputLabel --label-required">
									{Strings.profile.role}
								</label>
								<Select
									id="role"
									key={`role_${role}`}
									className="tagsSelector"
									style={{ width: "100%" }}
									placeholder={Strings.placeholders.role}
									showSearch
									filterOption={(input: any, option: any) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									value={role || null}
									onChange={(elem: any) => this.setState({ role: elem, hasUnsavedFields: true })}
								>
									<Select.Option value="admin">{Strings.staff.admin}</Select.Option>
									<Select.Option value="mechanic">{Strings.staff.mechanic}</Select.Option>
									<Select.Option value="driver">{Strings.staff.driver}</Select.Option>
									<Select.Option value="helper">{Strings.staff.helper}</Select.Option>
								</Select>
							</Col>
						</Row>
					</div>
				</ContentWrapper>
				<ContentWrapper>
					<Table
						title={{
							icon: "working-briefcase",
							title: Strings.staff.documents
						}}
						data={documents}
						columns={[
							{
								Header: Strings.fields.name,
								id: "description",
								accessor: (row: any) => row.description
							}
						]}
						filterable
						paginated={false}
						add={{
							onClick: () => this.setState({ tempDocument: {}, openSidebar: true })
						}}
						actions={{
							edit: (original: any) => ({
								onClick: () => this.setState({ tempDocument: { ...original }, openSidebar: true })
							}),
							view: (original: any) => ({
								onClick: () => window.open(original.file)
							}),
							remove: (original: any) => ({
								onClick: () => this.removeDocument(original._id)
							})
						}}
					/>
				</ContentWrapper>
				{this.renderDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language
});

export default connect(mapStateToProps)(StaffDetail);
