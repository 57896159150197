import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { Icon } from "components";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import "./styles.scss";

class WorksheetButton extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	render() {
		const { user, dispatch, mobile } = this.props;
		const { pendingWorksheet } = user || {};

		return (
			<div className="WorksheetButtonContainer">
				{!pendingWorksheet ? (
					<Button className="WorksheetButton --available" onClick={() => dispatch(push("worksheets/new"))}>
						<Icon className="WorksheetIcon" name="worksheet-add" />
						{mobile && <span>{Strings.worksheets.createWorksheet}</span>}
					</Button>
				) : (
					<Button
						className="WorksheetButton --unavailable"
						onClick={() => dispatch(push(`worksheets/${pendingWorksheet?._id}`))}
					>
						<Icon className="WorksheetIcon" name="worksheet-pending" />
						{mobile && <span>{Strings.worksheets.finishWorksheet}</span>}
					</Button>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	user: state.user,
	mobile: state.mobile
});

export default connect(mapStateToProps)(WorksheetButton);
