/*
 *
 * Login
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import Strings from "utils/strings";
import { Input, Button, Form, notification, Modal } from "antd";
import { setLoader, setUser, setToken } from "store/actions";
import { API, Endpoints } from "utils/api";
import logo from "assets/images/logo.png";
import "./styles.scss";

export class Login extends React.Component<any, any> {
    input: any;

    constructor(props: any) {
        super(props);

        this.state = {
            isMobile: window.innerWidth <= 768,
        };

        this.input = React.createRef();

        this.goToRegister = this.goToRegister.bind(this);
        this.login = this.login.bind(this);
        this.validateLogin = this.validateLogin.bind(this);
        this.failedLogin = this.failedLogin.bind(this);
        this.handleResize = this.handleResize.bind(this);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    goToRegister(e: any) {
        const { dispatch } = this.props;

        e.preventDefault();
        dispatch(push("/recover-password"));
    }

    async validateLogin() {
        const { dispatch } = this.props;
        const { email, password } = this.state;
        const body = {
            email,
            password,
        };

        dispatch(setLoader(true));

        try {
            const response = await API.post({ url: Endpoints.uriLogin(), data: body });
            if (response.ok) {
                if (response?.data?.code === 'AUTH_CODE_SENT') {
                    this.setState({ authCodeModal: true }, () => {
                        if (this.input) {
                            this.input.current?.focus();
                        }
                    });
                } else {
                    if (response.ok) {
                        const { user, token } = response.data.results;
                        dispatch(setUser({ ...user }));
                        dispatch(setToken(token));
                    }
                }
            } else {
                notification.error({
                    message: Strings.errors.invalidFields,
                    description: response?.data?.message || Strings.serverErrors.wentWrong,
                    placement: "bottomRight",
                    duration: 5,
                });
            }
        } catch (err) {
            this.failedLogin();
        }

        dispatch(setLoader(false));
    }

    async login() {
        const { dispatch } = this.props;
        const { email, password, authCode } = this.state;
        const body = {
            email,
            password,
        };

        dispatch(setLoader(true));

        let response: any;
        try {
            response = await API.post({ url: Endpoints.uriLogin(authCode), data: body });
            if (response.ok) {
                const { user, token } = response.data.results;
                dispatch(setUser({ ...user }));
                dispatch(setToken(token));
            } else {
                notification.error({
                    message: Strings.authentication.login,
                    description: response?.data?.message || Strings.serverErrors.wentWrong,
                    placement: "bottomRight",
                    duration: 5,
                });
            }
        } catch (err) {
            notification.error({
                message: Strings.authentication.login,
                description: Strings.serverErrors.wentWrong,
                placement: "bottomRight",
                duration: 5,
            });
        }

        dispatch(setLoader(false));
    }

    failedLogin() {
        notification.error({
            message: Strings.errors.invalidFields,
            description: Strings.errors.pleaseFillFormCorrectly,
            placement: "bottomRight",
            duration: 5,
        });
    }

    renderForm() {
        const { isMobile } = this.state;

        return (
            <div className={`LoginWrapper${isMobile ? " __isMobile" : ""}`}>
                <div className="LoginLogo">
                    <img style={{ height: 200 }} src={logo} alt="Login Logo" />
                </div>
                <div className="LoginContent">
                    <span className="LoginWelcome">{Strings.authentication.welcome}</span>
                    <Form name="login" onFinish={this.validateLogin} onFinishFailed={this.failedLogin}>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: Strings.errors.fillEmail },
                                { type: "email", message: Strings.errors.emailIsInvalid },
                            ]}
                        >
                            <Input
                                placeholder="your@email.com"
                                prefix={<Icon name="user m10r" />}
                                onChange={(e) => this.setState({ email: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: Strings.errors.fillPassword }]}>
                            <Input.Password
                                placeholder="password"
                                prefix={<Icon name="lock m10r" />}
                                visibilityToggle
                                onChange={(e) => this.setState({ password: e.target.value })}
                            />
                        </Form.Item>
                        <a href="/register" onClick={this.goToRegister} className="LoginForgotPassword">
                            {Strings.authentication.forgotPassword}
                        </a>
                        <Button type="primary" htmlType="submit" className="LoginButton">
                            {Strings.authentication.login}
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
    renderAuthCodeModal() {
        const { authCodeModal } = this.state;
        return (
            <Modal
                centered={true}
                closable={true}
                visible={authCodeModal}
                footer={null}
                width={300}
                onCancel={() => this.setState({ authCodeModal: false, authCode: "" })}
                title={<p style={{ marginBottom: 0 }}>{Strings.authentication.enterCode}</p>}
            >
                {this.renderAuthCode()}
            </Modal>
        );
    }

    renderAuthCode() {
        const { authCode } = this.state;
        return (
            <form
                name="login"
                onSubmit={(e: any) => {
                    e.preventDefault();
                    this.login();
                }}
            >
                <Input
                    placeholder="authy code"
                    prefix={<Icon name="user m10r" />}
                    value={authCode}
                    onChange={(e) => this.setState({ authCode: e.target.value })}
                    ref={this.input}
                />

                <div className="authyButton">
                    <Button type="primary" htmlType="submit" className="LoginButton">
                        {Strings.authentication.login}
                    </Button>
                </div>
            </form>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{Strings.authentication.login}</title>
                    <meta name="description" content="Description of Login" />
                </Helmet>
                {this.renderForm()}
                {this.renderAuthCodeModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Login);
